import React from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function TranxLimitModal(props) {
  return (
    <div>
    <Modal show={props.visibility} size="lg" aria-labelledby="contained-modal-title-vcenter" animation={false}  centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
          <img style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }} src="/dashboard/images/kyc-face-scan.png" width={"90px"} /> 
        </div>
        <h4 className='text-center'> 
    
  
          <span className='font-18'> <i className="fas fa-exclamation-triangle text-warning"></i> Verify Account </span> </h4>      
        <p style={{ marginBottom: "1px" }} className="text-center">
        <i> You have reached your daily transaction limit. kindly upgrade your account to remove restrictions. </i>
        </p>

        <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/kyc"} type="button"  className="btn btn-success btn-3d btn-sm btn-m font-600 shadow-bg shadow-bg-m shadow-s ">
                Verify Now <i className="fas fa-check-circle"></i>
            </Link>
        </div>

      </Modal.Body>
    </Modal>

    </div>
  )
}

export default TranxLimitModal