import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import ValidateCard from '../components/service/ValidateCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
import Swal from 'sweetalert2';
import { PulseLoader } from 'react-spinners';


function BulkSMS () {
    const navigate = useNavigate();

      const fetchSMS = async () => {
        dispatch({type: 'start'});
          await axios.post(baseURL+"/bulk-sms",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
            },
          }).then((response) => {
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
              // console.log(response.data);
              PINHandler(!response.data.pin);
              setCharge(response.data.data.charge);
              setWallet(response.data.balance);
                dispatch({type: 'stop'});
              } else {
                return toaster("error", error.loading.excemption);    
              }
    
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
            
          return toaster("error", error.loading.excemption);
              // return e;
          })
      }

    
    /**************************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [proceedLoading, dispatchProceedLoading] = useReducer(loadingReducer, false);
    const [wallet, setWallet] = useState('');
    const [charge, setCharge] = useState('');
    const [toggle, setToggle] =  useState(true);
    const [validateBulkNumber, setValidateBulkNumber] = useState(true);
    const btnTxt = validateBulkNumber === true ? "Proceed" : "Validate"
      const [value, setValue] = useState({
            img: '/dashboard/images/email.png',
            imgWidth: '35px',

            hideResult: true,
            validNumbers: '',
            InvalidNumbers: '',
            duplicateNumbers: '',
            senderName: '',
            phoneNumber: '',
            message: '',
            validate: {
              number: true,
            },
      });
   

/**************************************************************************/

  const toggleHandler = () => {
    let newToggleState = !toggle;
    let newValidateBulkNumber = !validateBulkNumber;
    // console.log(toggle);
      setValue({...value, hideResult: true, phoneNumber: ''});      
      setToggle(newToggleState);
      setValidateBulkNumber(newValidateBulkNumber);
 }


 function validateTextArea(result) {
    var arr = []; // New array of sanitized numberArray
    var InvalidNumber = []; // Array of invalid number(s) excluded
    var numbersArray = result.split(','); // Turn uploaded string to array
        //   Remove white space
    for (let i = 0; i < numbersArray.length; i++) {
        let number = numbersArray[i].trim()
            if (number !== "" && !isNaN(number)) {
              if (number.length == 11) {
                  arr.push(number);
              } else {
                  InvalidNumber.push(number);
              }
          }
      }
        const unique = Array.from(new Set(arr)); // Remove duplicate numbers
        var phoneNumber = unique.toString();
         // Duplicate numbers **** Subtract unique from total numbers
        let count = arr.length - unique.length;
        setValue({
          ...value,
          phoneNumber: phoneNumber,
          hideResult: false,
          duplicateNumbers: count,
          validNumbers: unique.length,
          InvalidNumbers: InvalidNumber.length,
        });
  }
  


 function phoneNumberHandler(e) {
    // validateTextArea(e.target.value);
    setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
  }
  
    
    /**************************************************************************/
    
    function proceed(e) {
      e.preventDefault();
      if (value.senderName == "") return toaster("error", error.validation.emptySenderName); 
      if (value.message == "") return toaster("error", error.validation.emptyMessage);     
      if (toggle == true && value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);      
      if (validateBulkNumber === false) {
        validateTextArea(value.phoneNumber);
        setValidateBulkNumber(true);
        return;
    }

    // console.log(value);

    Swal.fire({
      title: 'Are you sure?',
      text: "Before you proceed, please double-check the transaction you're about to make.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatchProceedLoading({type: 'start'});
        axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
        axios.post(baseURL+"/bulk-sms/send", {
          "service": "Bulk SMS",
          "sender": value.senderName,
          "phoneNumber": value.phoneNumber,
          "message": value.message,
      }).then((response) => {
        
          dispatchProceedLoading({type: 'stop'});
          if (response.data.code == 200) {
            // console.log(response);
            return storage("save", "receipt", response.data, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
          } else if(response.data.code !== 200 && response.data.status == "failed" && response.data.message !== "") {
              // dispatch({type: 'stop'});
              // console.log('test');
              return toaster("error", response.data.message);
          } else {
            return alert(error.whoops);
          }

        }).catch((e)=> {

          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }

        })

        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    })

         
  }
   
  

  const PINHandler = (value) => {
    setPinModal(value);
  }
   
  useEffect(() => {
    scrollToTop();
    fetchSMS();
    return () => axios.CancelToken.source();
  },[]);

  
    
    return (
        <div>


      <TopBar icon="fas fa-sms"/>
    
        <div className={"page-content " + Styles.page_content}>
    
        <TopCard wallet={wallet} />

    
        <div className="card card-clear" style={{ height: "170px" }}></div>
        
    
    <div className={"page-content pb-4 " + Styles.pt_10}>
   

    <ToastContainer/>

    
    <div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>
    
    
    <div className={"content mb-0 " + Styles.pt_13}>
    
    <SetPin visibility={pinModal} state={PINHandler} />

<div hidden={!loading}>

    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>

      <div>
        <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
        <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme>
        <SkeletonTheme height={100} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
        {loading ? <SkeletonTheme height={35} width={35}> <Skeleton ount={1}/> </SkeletonTheme> : null }
        <SkeletonTheme height={30} width={190}> <Skeleton  count={1}/> </SkeletonTheme>
        <SkeletonTheme height={30} width={50}> <Skeleton count={1}/> </SkeletonTheme>
      </div>

      {loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}

      </div>





<span hidden={loading}>

    <h3><span> Bulk SMS </span>
    
    <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: ""}}>
          <img src={ value.img } className="text-center" width={value.imgWidth} style={{  borderRadius: "50%"  }} />
          </span></h3>
    <p className={ Styles.description_txt }>
    
    Send message to your friends and family at low cost. 
    Fill up the fields below and click proceed.
    
    </p>
    
    <span className='mb-2' hidden={charge == '' ? true : false}>Service Charge: ₦{charge}</span>

<div className="col-lg-12 input-style has-borders has-icon validate-field mb-4">
<i className="fa fa-arrow-right" style={{ color: "#ff0000" }}></i>
<input type="text" maxLength={12} autoComplete="off" value={value.senderName} onChange={(e) => setValue({...value, senderName: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Enter sender name" />
{/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<div className="col-lg-12 input-style has-borders has-icon validate-field mb-4" hidden={ !toggle  }>
<i className="fa fa-phone" style={{ color: "#008000" }}></i>
<input type="number" autoComplete="off" pattern={'[0-9]*'} value={value.phoneNumber} onChange={phoneNumberHandler} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Enter recepient phone number" />
{/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>





    <div className="input-style has-borders no-icon mb-4" hidden={toggle}>
    <textarea id="form7" value={value.phoneNumber} onChange={phoneNumberHandler} onKeyUp={() => setValidateBulkNumber(false)} className={ Styles.txtarea } 
    placeholder="Enter phone numbers seperated by comma (,) e.g 080********, 090********,070********,081********"></textarea>
    <label htmlFor="form7" className="color-highlight">
    Enter phone numbers
    </label>
    <em className="mt-n3">(required)</em>
    </div>



    <div className="input-style has-borders no-icon mb-4">
    <textarea id="form7" value={value.message} onChange={(e) => setValue({...value, message: e.target.value})} className={ Styles.txtarea } 
    placeholder="Enter your message here ..."></textarea>
    {/* <label htmlFor="form7" className="color-highlight">
    Enter your message here
    </label> */}
    <em className="mt-n3">(required)</em>
    </div>





<ValidateCard hideResult={value.hideResult} validNumbers={value.validNumbers} InvalidNumbers={value.InvalidNumbers} duplicateNumbers={value.duplicateNumbers}/>


<div className="list-group list-custom-small">
<a data-switch="switch-1" href={void(0)} style={{ color: "#1f1f1f" }} >
<i className={"fa font-14 fa-check-circle rounded-s bg-yellow-dark " + Styles.check_font}></i>
<span>Multiple phone number</span>
<div className="custom-control scale-switch ios-switch" >
<input  type="checkbox" className="ios-input" id="switch-1"/>
<label className="custom-control-label" htmlFor="switch-1" onClick={toggleHandler}></label>
</div>
<i className="fa fa-chevron-right opacity-30"></i>
</a>



</div>




<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>

  { proceedLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
  { proceedLoading ? '' : btnTxt }
  </a>
    

  </span>
    
    
                </div>
            </div>
            <div className="mb-5"></div>
        </div>
    
    </div>
    

    </div>
    
        );
    }


export default BulkSMS