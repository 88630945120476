import React from 'react'
import Styles from '../../pages/Styles.module.css';

function ValidateCard(props) {
  return (
    <div>

    <div style={{ border: "1px solid #f0f8ff7d", padding: "10px", borderRadius: "5px", textAlign: "center", backgroundColor: "#f0f8ff7d"  }} hidden={props.hideResult}>
        <strong className={"color-black font-400 " + Styles.icon_font_color }>
            <i className={"fa font-14 fa-check-circle rounded-s bg-yellow-dark " + Styles.bg_green }></i> { props.validNumbers }  valid phone number(s) found.</strong> <br/>

        <strong className={"color-black font-400 " + Styles.icon_font_color }>
        <i className={"fa font-14 fa-times-circle rounded-s bg-yellow-dark " + Styles.bg_red }></i> { props.InvalidNumbers }  Invalid number(s) were removed.</strong> <br/>

        <strong className={"color-black font-400 " + Styles.icon_font_color }>
        <i className={"fa font-14 fa-times-circle rounded-s bg-yellow-dark " + Styles.bg_red }></i> { props.duplicateNumbers } duplicate number(s) were removed.</strong>
    </div>


    </div>
  )
}

export default ValidateCard