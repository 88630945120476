import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage, decimalCheck} from '../functions/O3developer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import ValidateCard from '../components/service/ValidateCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
// import LoadingModal from '../components/modals/LoadingModal';
import PulseLoader from "react-spinners/PulseLoader";
import {Modal, Button, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';


function Airtime () {
const navigate = useNavigate();

  // Fetch
  const fetchAirtime = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/airtime",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
          // console.log(response.data);
      if (response.data.status == "failed" && response.data.message !== "") {          
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          PINHandler(!response.data.pin);
              setValue({
                ...value,
                airtime: response.data.airtime,
                prefix: response.data.prefix,
                networks: response.data.networks,
              });
                setWallet(response.data.balance);
                setBeneficiaryList(response.data.beneficiaries);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }
      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }

  
/**************************************************************************/
// Validate Number
  const validateNumber = (phoneNumber) => {
    if (value.validate.number == true) {
      if (phoneNumber.length == 11) {
        let networkPrefix =  value.prefix.filter((e)=> {
              return phoneNumber.startsWith(e.prefix);
          });
          if (networkPrefix.length == 1) {
          let network = networkPrefix[0].network;
              document.getElementById("network").value = network;
              setNetwork(network);
              networkHandler({target: {value: network}});
          } else {
              document.getElementById("network").selectedIndex = 0;
          }

      } else {
          setNetwork("");
          networkHandler({target: {value: ""}});
          document.getElementById("network").selectedIndex = 0;

        }
      }
  }

/**************************************************************************/

const [loading, dispatch] = useReducer(loadingReducer, false);
const [pinModal, setPinModal] = useState(false);
  const [network, setNetwork] =  useState('');
  const [wallet, setWallet] = useState('');
  const [type, setType] = useState('');
  const [airtimeTypes, setAirtimeTypes] = useState([]);
  const [networkAirtime, setNetworkAirtime] = useState([]);
  const [toggle, setToggle] =  useState(true);
  const [amountCharge, setAmountCharge] = useState('');
  const [showDiscount, setShowDiscount] = useState(false);
  const [validateBulkNumber, setValidateBulkNumber] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [beneficiaryToggle, setBeneficiaryToggle] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [BenLoading, dispatchBenLoading] = useReducer(loadingReducer, false);
  const [value, setValue] = useState({
        // textarea: '',
        hideResult: true,
        validNumbers: '',
        InvalidNumbers: '',
        duplicateNumbers: '',
        airtime: [],
        amount: '',
        phoneNumber: '',
        validate: {
          number: true,
        },
        prefix: [],
        networks: [],
  });

/**************************************************************************/

  const toggleHandler = () => {
    let newToggleState = !toggle;
    let newValidateBulkNumber = !validateBulkNumber;
    // console.log(toggle);
      setValue({...value, hideResult: true, phoneNumber: ''});
      setToggle(newToggleState);
      setValidateBulkNumber(newValidateBulkNumber);
  }


// Beneficiary Handler
  const beneficiaryHandler = () => {
    let newToggleState = !beneficiaryToggle;
    setBeneficiaryToggle(newToggleState);
    setModalShow(newToggleState);
    // console.log(newToggleState);
  }



/**************************************************************************/

function validateTextArea(result) {
  var arr = []; // New array of sanitized numberArray
          var InvalidNumber = []; // Array of invalid number(s) excluded
          var numbersArray = result.split(','); // Turn uploaded string to array
              //   Remove white space
          for (let i = 0; i < numbersArray.length; i++) {
              let number = numbersArray[i].trim()
                  if (number !== "" && !isNaN(number)) {
                    if (number.length == 11) {
                        arr.push(number);
                    } else {
                        InvalidNumber.push(number);
                    }
                }
            }
              const unique = Array.from(new Set(arr)); // Remove duplicate numbers
              var phoneNumber = unique.toString();
              let count = arr.length - unique.length; // Duplicate numbers **** Subtract unique from total numbers
              setValue({
                ...value,
                phoneNumber: phoneNumber,
                hideResult: false,
                duplicateNumbers: count,
                validNumbers: unique.length,
                InvalidNumbers: InvalidNumber.length,
              });
}


const fileHandler = (event) => {
  var file = event.target.files[0];
  if (file.name.includes(".txt") || file.name.includes(".csv")) {
      var reader = new FileReader();
      reader.onload = function(event) {
          var result = event.target.result;
          setValidateBulkNumber(false);
          setValue({...value, phoneNumber: result});
          
      }
            reader.readAsText(file);
  } else {
      return toaster("error", error.validation.invalidCSVTXT);
  }

}


/**************************************************************************/


const [image, setImage] = useState({
  src: '/dashboard/images/networks.jpg',
  borderColor: '#122614 #edc704 #17a00b #ed1d23',
  imageWidth: '37px',
  spanBorder: '1px solid',
  rotate: 'rotate',
})


const networkImage = (network) => {
        // console.log(network);
    switch (network) {
      // MTN
    case "MTN": setImage({
        src: "/dashboard/images/mtn.jpg",
        borderColor: '#f9c905',
        imageWidth: '35px',
        spanBorder: '2px solid',
        rotate: '',
    });
    break;
    // GLO
    case "GLO": setImage({
      src: "/dashboard/images/glo.jpg",
      borderColor: '#48b83e',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // AIRTEL
    case "AIRTEL": setImage({
      src: "/dashboard/images/airtel.jpg",
      borderColor: '#ed1b24',
      imageWidth: '35px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // 9MOBILE
    case "9MOBILE": setImage({
      src: "/dashboard/images/9mobile.jpg",
      borderColor: '#006d52',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

      default: setImage({
        src: '/dashboard/images/networks.jpg',
        borderColor: '#122614 #edc704 #17a00b #ed1d23',
        imageWidth: '37px',
        spanBorder: '1px solid',
        rotate: 'rotate',
      });

    }

}


/**************************************************************************/


const networkHandler = (e) => {
  var network = e.target.value;
    setNetwork(network);
    networkImage(network);
    setAirtimeTypes([]);
    setNetworkAirtime([]);
    setType('');
    setShowDiscount(false);
    setValue({...value, amount: ''});
  var airtime = value.airtime;
  let types = [];

  if (network !== "") {
    for (let i = 0; i < airtime.length; i++) {
      if (airtime[i].network === network) {
        types.push(airtime[i].type);
      }
    }
    document.getElementById("type").selectedIndex = 0;
      const unique = Array.from(new Set(types));
    //  console.log(unique);
      if (unique.length == 0) {
        toaster("error", error.service.notAvailable);
   
      } else if (unique.length == 1)  {      
        var airtime = value.airtime.filter((e) => {
          return e.network == network && e.type == unique[0];
      });
      setType(unique[0]);
      setNetworkAirtime(airtime);
      } else {
        setAirtimeTypes(unique);
      } 
   
  } 
};

/**************************************************************************/

function typeHandler(e) {
  var type = e.target.value;
  setShowDiscount(false);
  setValue({...value, amount: ''});
  if (type !== "") {
    var airtime = value.airtime.filter((e) => {
        return e.network == network && e.type == type;
    })
    setType(type);
    setNetworkAirtime(airtime);
    // console.log(airtime);
  } else {
    setType('');
    setNetworkAirtime([]);
    setValue({...value, amount: ''});
  }
}

/**************************************************************************/

function amountHandler(e) {
  var amount = e.target.value;
  if (amount < 10000001) {
  setValue({...value, amount: amount})
  var discount = networkAirtime[0].discount;
  //
  if (discount !== '' || discount !== 0) {
    let discount_percentage = discount/100;
    let discount_amount = discount_percentage * amount;
    let amount_charge = amount - discount_amount;
    setAmountCharge(Math.round((amount_charge + Number.EPSILON) * 100) / 100);
  } else {
    setAmountCharge(amount);
  }
  //
  if (amount < 50 || amount == '' || networkAirtime[0].discount == '' || networkAirtime[0].discount == 0) {
    setShowDiscount(false);
  } else {
    setShowDiscount(true);
  }
}

}



function phoneNumberHandler(e) {
  // validateTextArea(e.target.value);
  setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
}




/**************************************************************************/

function proceed(e) {
  e.preventDefault();
  if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
  if (network == "") return toaster("error", error.validation.emptyNetwork);
  if (type == "") return toaster("error", error.validation.emptyType);
  if (networkAirtime.length !== 1) return toaster("error", error.validation.requiredInput);
  if (value.amount == '' || value.amount < 1) return toaster("error", error.validation.emptyAmount);
  if (toggle == true && value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
  if (value.amount < 50) return toaster("error", "The amount entered falls short of the required minimum (₦50).");
  if (decimalCheck(value.amount)) return toaster("error", "Amount not allowed.");

  if (validateBulkNumber === false) {
    validateTextArea(value.phoneNumber);
    setValidateBulkNumber(true);
    return;
  }

  let phoneNumberArray = value.phoneNumber.split(',');
  let amount = phoneNumberArray.length * amountCharge;
  // console.log(typeof(amount) + ' ' + typeof(amountCharge) + ' ' + typeof(wallet));
  if (wallet < amount && wallet !== amount) return toaster("error", error.lowBalance)


  let object = {
      "service": "Airtime Topup",
      "id" : networkAirtime[0].id,
      "network" : network,
      "number": value.phoneNumber,
      "type": type,
      "amount": value.amount,
      "amount_to_pay": amount,
  };
  object = JSON.stringify(object);
  if(storage("save", "invoice", object, "sessionStorage")) {
      navigate("/invoice");
  } else {
      return alert(error.whoops);
  }
}


/**************************************************************************/
// Paste number
  function pasteNumber () {
    var phoneNumber = user().phone_number;
    validateNumber(phoneNumber);
    setValue({ ...value, phoneNumber: phoneNumber, amount: '' });
  }


// Paste Beneficiary number

function pasteBeneficiaryNumber (phoneNumber) {
  validateNumber(phoneNumber);
  setValue({ ...value, phoneNumber: phoneNumber });
  setModalShow(false);
}


  const PINHandler = (value) => {
    setPinModal(value);
  }






  

function BeneficiaryList(props) {
  return (
    <>

<a className={"card card-style py-4 mx-0 mb-3 " + Styles.card_border}>
        <div className="d-flex px-3">
        <div className="align-self-center">
          <img src="/dashboard/images/address-book.png" height="60" width="60" className="rounded-xl" />
        </div>
        <div className="ps-2 align-self-center" style={{ lineHeight: "20px" }}>
        <h4 className="mb-1"> {props.data.name} </h4>
        <span className="font-12 color-black opacity-70" > {props.data.number} </span>
        </div>
        <div className="ms-auto align-self-center">
        <span className="ms-2 badge" style={{backgroundColor: 'green', cursor: "context-menu"}} onClick={() => pasteBeneficiaryNumber(props.data.number)}> Select <i className="fas fa-check-circle"></i> </span>
        <span className="ms-2 badge" style={{backgroundColor: 'red', cursor: "context-menu"}} onClick={() => removeBeneficiary(props.data.id)}> Remove <i className="fas fa-times-circle"></i> </span>
        </div>
        </div>
      </a>

    </>
  )
}





const [beneficiaryNumber, setBeneficiaryNumber] = useState('');
const [beneficiaryName, setBeneficiaryName] = useState('');

const saveBeneficiary = () => {
  if (beneficiaryNumber !== "" && beneficiaryName !== "") {
    // console.log(beneficiaryNumber + ' hello');
    if (beneficiaryNumber.length == 11) {

      dispatchBenLoading({type: 'start'});
    axios.post(baseURL+"/beneficiary/save",  {
        beneficiaryName: beneficiaryName,
        beneficiaryNumber: beneficiaryNumber
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
    }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          dispatchBenLoading({type: 'stop'});
            return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
              dispatchBenLoading({type: 'stop'});
              setBeneficiaryName('');
              setBeneficiaryNumber('');
              // beneficiaryList.push(response.data.data);
              // console.log(beneficiaryList);
              setBeneficiaryList(response.data.data);
              return toaster("success", response.data.message);                      
            } else {
              dispatchBenLoading({type: 'stop'});
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })

    } else {
      return toaster("error", error.validation.invalidPhoneNumber);
    }

  } else {
      return toaster('error', error.validation.requiredInput);
  }
}



const removeBeneficiary = (id) => {
    // dispatch({type: 'start'});
    axios.post(baseURL+"/beneficiary/remove",  {
        id: id,
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
    }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
            return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          let list = beneficiaryList.filter(e => e.id !== id);
            // console.log(list);
            setBeneficiaryList(list);
            return toaster("success", response.data.message);          
                // dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
}





useEffect(() => {
  scrollToTop();
  fetchAirtime();
    // 
  window.addEventListener("resize", () => {
    const ismobile = window.innerWidth < 1200;
    if (ismobile !== isMobile) setIsMobile(ismobile);
  }, false);
    //
  return () => axios.CancelToken.source();
},[isMobile]);






return (
    <div>


    {/* Top Bar  */}
    <TopBar icon="fas fa-mobile-alt"/>

    <div className={"page-content " + Styles.page_content}>

  {/* Top Card */}
  <TopCard wallet={wallet}/>

<div className="card card-clear" style={{ height: "170px" }}></div>



<div className={"page-content pb-6 " + Styles.pt_10}>

<ToastContainer /> 


<div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>

<div className={"content mb-0 " + Styles.pt_13}>

<SetPin visibility={pinModal} state={PINHandler} />



<div hidden={!loading}>

     <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>



{loading ? <SkeletonTheme height={25} width={100}> <Skeleton count={1}/> </SkeletonTheme> : null }


<div>
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
</div>


<div style={{ display: "flex", justifyContent: "space-between", borderBottom: "solid 1px rgba(0, 0, 0, 0.05)", paddingBottom: "10px" }}>
      {loading ? <SkeletonTheme height={35} width={35}> <Skeleton ount={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={30} width={190}> <Skeleton  count={1}/> </SkeletonTheme>

          <SkeletonTheme height={30} width={50}> <Skeleton count={1}/> </SkeletonTheme>
      </div>



<div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px" }}>

{loading ? <SkeletonTheme height={35} width={35}> <Skeleton ount={1}/> </SkeletonTheme> : null }
    <SkeletonTheme height={30} width={190}> <Skeleton  count={1}/> </SkeletonTheme>
    <SkeletonTheme height={30} width={50}> <Skeleton count={1}/> </SkeletonTheme>
</div>


{loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}

</div>




<span hidden={loading}> 





<div className='col-lg-5 float-start container d-none d-lg-block'>



<div className='row mt-2 mb-0'>
  <div className='col-lg-12'>
  <Link to="/transaction" className={"card card-style py-4 mx-0 mb-3 " + Styles.card_border}>
        <div className="d-flex px-3">
        <div className="align-self-center">
          <img src="/dashboard/images/transaction-history.png" height="60" width="60" className="rounded-xl" />
        </div>
        <div className="ps-2 align-self-center" style={{ lineHeight: "20px" }}>
        <h4 className="mb-1"> History </h4>
        <span className="font-12 color-black opacity-70" > View all transaction history </span>
        </div>
        <div className="ms-auto align-self-center">
        <i className="fa fa-arrow-right opacity-30 color-theme"></i>
        </div>
        </div>
      </Link>
    </div>

  
  <div className='col-lg-12'>
  <Link to="/fund-wallet" className={"card card-style py-4 mx-0 mb-3 " + Styles.card_border}>
        <div className="d-flex px-3">
        <div className="align-self-center">
        <img src="/dashboard/images/credit-card_.png" height="60" width="60" className="rounded-xl" />
        </div>
        <div className="ps-2 align-self-center" style={{ lineHeight: "20px" }}>
        <h4 className="mb-1"> Fund Wallet </h4>
        <span className="font-12 color-black opacity-70" > Topup your wallet balance </span>
        </div>
        <div className="ms-auto align-self-center">
        <i className="fa fa-arrow-right opacity-30 color-theme"></i>
        </div>
        </div>
      </Link>
    </div>


</div>






<div className={"card card-style m-0 " + Styles.card_border}>
  <div className="content mb-0">
  <h4 className="font-700">Other Services</h4>
  <p className='mb-1'>
  You can also make purchase for any of the service below.
  </p>
    <Link to="/airtime" className="chip chip-s" style={{ background: 'purple' }}>
      <img src="/dashboard/images/airtime.svg" className='bg-white' alt="img"/>
      <span className="color-white">Buy Airtime</span>
    </Link>

    <Link to="/cable" className="chip chip-s bg-red-dark" style={{ background: '#009688' }}>
      <img src="/dashboard/images/Multichoice-banner.jpg" className='bg-white' alt="img"/>
      <span className="color-white">Cable Subscription</span>
    </Link>

    {/* <Link to="/spectranet" className="chip chip-s bg-blue-dark">
      <img src="/dashboard/images/spectranet-device.png" className='bg-white' alt="img"/>
      <span className="color-white">Spectranet</span>
    </Link> */}

    {/* <Link to="/smile" className="chip chip-s" style={{ background: 'orange' }}>
      <img src="/dashboard/images/smile.png" className='bg-white' alt="img"/>
      <span className="color-white">Smile Bundle</span>
    </Link> */}

    <Link to="/electricity" className="chip chip-s" style={{ background: '#ff5722' }}>
    <img src="/dashboard/images/electricity-power-line.jpg" className='bg-white' alt="img"/>
    <span className="color-white">Electricity</span>
    </Link>

  </div>
</div>



</div>










<div className='col-lg-7 col-md-12 col-xs-12 col-sm-12 float-end data_card_transform'>
  
<div className={!isMobile ? "container" : ""}>
<div className="lg_card_raise">
  
<h3><span> Airtime Topup </span>

<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%', border: image.spanBorder, borderColor: image.borderColor}}>
      <img src={ image.src } className={"text-center " + image.rotate} width={image.imageWidth} style={{  borderRadius: "50%"  }} />
      </span></h3>
<p className={ Styles.description_txt }>

Select a network from the list below and enter an amount and phone number 📱.

</p>

<span className={"badge border color-black border-green-dark p-1 shadow-s " + Styles.pasteMyNumber } onClick={pasteNumber} style={{cursor: "context-menu"}}> Paste my number </span>

</div>


{/* <LoadingModal text="" key="load" visibility={loading}/> */}


<div className="input-style has-borders has-icon validate-field mb-4" hidden={ !toggle  }>
<i className="fa fa-phone" style={{ color: "#008000" }}></i>
<input type="number" autoComplete="off" pattern={'[0-9]*'} onKeyUp={(e) => validateNumber(e.target.value)} value={value.phoneNumber} onChange={phoneNumberHandler} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Phone Number" />
{/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<div className="input-style has-borders no-icon mb-4">
        <select id="network" onChange={networkHandler} value={network} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.networks.length !== 0 ? value.networks.map((e, index) => { return <option value={e.name} key={index}> {e.name} </option> }) : ''}
        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>



<div className="input-style has-borders no-icon mb-4" hidden={ airtimeTypes.length > 0 ? false : true  }>
        {/* <label htmlFor="type" className="color-highlight">Type</label> */}
        <select id="type" className="shadow-bg shadow-bg-m shadow-s" onChange={typeHandler}>
        <option value=""> Please select  </option>
        { airtimeTypes.length !== 0 ? airtimeTypes.map((e, index) => { return <option value={e} key={index}> {e} </option> }) : ''}

        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
    </div>



    <div className="input-style has-borders has-icon validate-field mb-4" hidden={ networkAirtime.length > 0 ? false : true }>
    {/* <i className="fa fa-money" style={{ color: "#000094" }}></i> */}
    <i className="fas fa-money-bill-alt"></i>
    <input type="number" autoComplete="off" value={value.amount} onChange={amountHandler} className="form-control validate-name shadow-bg-m shadow-s" id="amount" placeholder="Enter amount" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
    </div>


<div style={{float: "right", color: "red", fontSize: '11.5px'}}
hidden={!showDiscount}>
You will be charged ₦{amountCharge} instead of ₦{value.amount} 😊
</div>




<div className="upload-btn-wrapper shadow-bg-m shadow-s" hidden={toggle} style={{  marginBottom: "10px", borderRadius: "10px"  }} >
  <button className="upload_btn shadow-bg-m shadow-s" style={{ lineHeight: "35px" }}>
      <i className="fa fa-upload" aria-hidden="true"></i> Upload .CSV/TXT file
</button>
  <input type="file" onChange={fileHandler} name="myfile"/>
</div>


{/* ----------------------------------------- */}


<div className="input-style has-borders no-icon mb-4" hidden={toggle}>
<textarea id="form7" value={value.phoneNumber} onChange={phoneNumberHandler} onKeyUp={() => setValidateBulkNumber(false)} className={ Styles.txtarea } placeholder="Enter phone numbers seperated by comma (,) e.g 080********, 090********,070********,081********"></textarea>
<label htmlFor="form7" className="color-highlight">
  Enter phone numbers
</label>
<em className="mt-n3">(required)</em>
</div>




<ValidateCard hideResult={value.hideResult} validNumbers={value.validNumbers} InvalidNumbers={value.InvalidNumbers} duplicateNumbers={value.duplicateNumbers}/>


<div className="list-group list-custom-small" style={{marginTop: showDiscount ? '40px' : '0px' }}>

<a data-switch="switch-1" href={void(0)} style={{ color: "#1f1f1f" }}>
<i className={"fa font-14 fa-check-circle rounded-s bg-yellow-dark " + Styles.check_font}></i>
<span>Multiple phone number</span>
<div className="custom-control scale-switch ios-switch">
<input  type="checkbox" className="ios-input" id="switch-1"/>
<label className="custom-control-label" htmlFor="switch-1" onClick={toggleHandler}></label>
</div>
{/* <i className="fa fa-chevron-right opacity-30"></i> */}
</a>


<a href={void(0)} style={{ borderBottom: "1px solid white", color: "#1f1f1f" }}>
<i className={"fa font-14 fa-user-circle rounded-s bg-yellow-dark " + Styles.user_font }></i>
<span>Select from beneficiary list</span>
<div className="custom-control scale-switch ios-switch">
<input type="checkbox" className="ios-input" id="switch"/>
<label className="custom-control-label" htmlFor="switch" onClick={beneficiaryHandler}></label>
</div>
{/* <i className="fa fa-chevron-right opacity-30"></i> */}
</a>




</div>


<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>
    { validateBulkNumber === true ? "Proceed" : "Validate" } 
  </a>

</div></div>

  </span>


            </div>
        </div>
        {/* <div className="mb-5"></div> */}
    </div>






{/* Beneficiary List */}

<div>
      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      
          <ModalBody style={{ color: '#758698' }}>

            <button type="button" className="close mb-2" data-dismiss="modal" aria-label="Close"
              onClick={() => setModalShow(false)}>
                Close
              <span aria-hidden="true"> 
                <i className="fas fa-times" style={{ color: '#0000004f', padding: '6px', borderRadius: '50%' }}></i>
              </span>
            </button>


        { beneficiaryList.length > 0 ? beneficiaryList.map((e, i) => <BeneficiaryList data={e} key={i} /> ) : '' }


              <div className="col-lg-12 input-style has-borders has-icon mb-4 mt-3">
              <i className="fa fa-phone" style={{ color: "#008000" }}></i>
              <input type="number" autoComplete="off" pattern={'[0-9]*'} className="form-control" id="number" value={beneficiaryNumber} onChange={(e) => setBeneficiaryNumber(e.target.value)} placeholder="Phone Number" />
              {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
              <i className="fa fa-times disabled invalid color-red-dark"></i>
              <i className="fa fa-check disabled valid color-green-dark"></i>
              <em>(required)</em>
              </div>



              <div className="col-lg-12 input-style has-borders has-icon mb-4">
              <i className="fa fa-user text-primary"></i>
              <input type="text" autoComplete="off" className="form-control" id="name" value={beneficiaryName} onChange={(e) => setBeneficiaryName(e.target.value)} maxLength={'15'} placeholder="Enter Beneficiary Name" />
              {/* <label htmlFor="form1" className="color-highlight">Name</label> */}
              <i className="fa fa-times disabled invalid color-red-dark"></i>
              <i className="fa fa-check disabled valid color-green-dark"></i>
              <em>(required)</em>
              </div>

          <a href={void(0)} className={"btn btn-3d btn-full btn-m btn-primary font-800 rounded-sm shadow-bg shadow-bg-m shadow-s"} onClick={saveBeneficiary}>
              { BenLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
              { BenLoading ? '' : 'Add Beneficiary' }
            </a>

          </ModalBody>

      </Modal>
  </div>



{/* Beneficiary List Ends Here */}






</div>


</div>

    );
}


export default Airtime;
