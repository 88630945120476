import React, { useEffect, useReducer, useState } from 'react'
import { baseURL, scrollToTop, storage, toaster, user} from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Styles from '../Styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';


function VerifyBVN() {
    const { state } = useLocation();

    const navigate = useNavigate();
    async function update (e) {
        e.preventDefault();       
        if (bvn == "") return toaster("error", error.validation.requiredInput);
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/kyc/verify-bvn",  {
                bvn: bvn,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    setTimeout(() => {
                        navigate("/kyc/process");
                    }, 2000);
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }
            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }
    
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [bvn, setBVN] = useState('')

    useEffect(() => {
        scrollToTop();
        if (state && state.route !== 'KYC') {         
            return navigate("/kyc/process");
        } else if(state == undefined || state == null) {
            return navigate("/kyc/process");
        }
    }, [])


  return (
    <div>        
        
  <ToastContainer /> 

            <PageTopBar title="BVN"/>

        <div className="page-title-clear"></div>
        
        <div className="page-content">


        <div className="card dashboard-layout card-style">

        <div className='content mb-2'>

        <h3> BVN Verification 
        <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
            <img src="/dashboard/images/bvn_logo.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
            </span></h3>
        <p className={ Styles.description_txt }>
        To verify that the given account number belongs to you, we have to check your BVN.         
        </p>


        <div className="input-style has-borders has-icon validate-field mb-4 col-lg-12">
        <i className="fas fa-arrow-right"></i>
        <input type="number" value={bvn} onChange={e => setBVN(e.target.value)} className="form-control" placeholder="Enter your bank verification number" autoComplete="off" required />
        <label htmlFor="form1" className="color-highlight">BVN</label>
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(required)</em>
        </div>


        <a href="#" onClick={update} className="btn btn-full btn-sm rounded-s font-600 font-13 mb-3" style={{ color: "white", backgroundColor: "green" }}>
        {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Submit"}  </a>

        </div>

        </div>


    </div>

    </div>
  )
}

export default VerifyBVN