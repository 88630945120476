import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage, siteName} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
// import LoadingModal from '../components/modals/LoadingModal';


function Exam () {
    const navigate = useNavigate();

      const fetchExams = async () => {
        dispatch({type: 'start'});
          await axios.post(baseURL+"/exam",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
      }).then((response) => {
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
              // console.log(response.data);
              PINHandler(!response.data.pin);
                  setValue({
                    ...value,
                    exams: response.data.exams,
                    });
                    setWallet(response.data.balance);
                    dispatch({type: 'stop'});
              } else {
                return toaster("error", error.loading.excemption);    
              }
    
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              
            return toaster("error", error.loading.excemption);
              // return e;
          })
      }

    
    /******************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [wallet, setWallet] = useState('');
      const [value, setValue] = useState({
            img: '/dashboard/images/graduation-cap-congratulation.png',
            imgWidth: '35px',
            exams: [],
            exam: '',
            selectedExam: [],
            quantity: '',
            amount: ''
      });
   
      function examHandler(e) {
        var exam = e.target.value;
        document.getElementById("quantity").selectedIndex = 0;
        if (exam !== "") {
          var exams = value.exams.filter((e) => {
              return e.exam == exam
          })
          setValue({...value, exam: exam, quantity: '', amount: '', selectedExam: exams});
        } else {
          setValue({...value, exam: '', quantity: '', amount: '', selectedExam: []});
        }
      }
    
    
    /****************************************************************/
      function quantityHandler(e) {
        if (value.selectedExam.length !== 0) {
            let quantity = e.target.value;
            let price = value.selectedExam[0].amount;
            let amount = price * quantity;
            setValue({...value, quantity: quantity, amount: amount})
        } 
      }

    /*******************************************************************/
    
    function proceed(e) {
      e.preventDefault();
      if (value.selectedExam.length !== 1) return toaster("error", error.validation.emptyExam);     
      if (value.quantity == "") return toaster("error", error.validation.emptyQuantity);
      if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);

      let object = {
          "service": "Result Checker",
          "id" : value.selectedExam[0].id,
          "quantity": value.quantity,
          "exam": value.selectedExam[0].exam,
          "amount": value.amount,
      };
      object = JSON.stringify(object);
      if(storage("save", "invoice", object, "sessionStorage")) {
          navigate("/invoice");
      } else {
          return alert(error.whoops);
      }
    
     
  }
    

  const PINHandler = (value) => {
    setPinModal(value);
  }
   
  useEffect(() => {
    scrollToTop();
    fetchExams();
    return () => axios.CancelToken.source();
  },[]);


  
    
    return (
        <div>


      <TopBar icon="fas fa-graduation-cap"/>
    
        <div className={"page-content " + Styles.page_content}>
    
        <TopCard wallet={wallet} />

    
        <div className="card card-clear" style={{ height: "170px" }}></div>
    
    
        {/* <LoadingModal text="" key="load" visibility={loading}/> */}
    
    
    <div className={"page-content pb-4 " + Styles.pt_10}>
   
    <ToastContainer/>
    
    <div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>
    
    
    <div className={"content mb-0 " + Styles.pt_13}>

    <SetPin visibility={pinModal} state={PINHandler} />

    <div hidden={!loading}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>

    <div>
      <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme>       
    </div>

    {loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}    
    </div>



    
    <span hidden={loading}>

    <h3><span> Result Checker </span>
    
    <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: ""}}>
          <img src={ value.img } className="text-center" width={value.imgWidth} style={{  borderRadius: "50%"  }} />
          </span></h3>
    <p className={ Styles.description_txt }>
    
      Kindly select exam and quantity below then click on proceed.
    
    </p>
    


    <div className="input-style has-borders no-icon mb-4">
        <select id="exam" onChange={examHandler} value={value.exam} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.exams.length !== 0 ? value.exams.map((e, index) => { return <option value={e.exam} key={index}> {e.exam} </option> }) : ''}
        </select>
            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
      </div>
    

    
    <div className="input-style has-borders no-icon mb-4" hidden={ value.selectedExam.length > 0 ? false : true }>
        {/* <label htmlFor="form5" className="color-highlight">Plan</label> */}
        <select id="quantity" onChange={ quantityHandler } className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Select quantity</option>
        <option value="1"> 1 </option>
        <option value="2"> 2 </option>
        <option value="3"> 3 </option>
        <option value="4"> 4 </option>
        <option value="5"> 5 </option>
        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>
      

 
  <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.quantity !== '' ? false : true }>
    {/* <i className="fa fa-money" style={{ color: "#000094" }}></i> */}
    <i className="fas fa-money-bill-alt"></i>
    <input type="text" readOnly autoComplete="off" value={value.amount} onChange={ (e) => {setValue({...value, amount: e.target.value})} } className="form-control validate-name shadow-bg-m shadow-s" id="amount" placeholder="Enter amount" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    {/* <em>(required)</em> */}
    </div>




    <a href="#" className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>
        Proceed </a>
    
        </span>

    
    
    
                </div>
            </div>
            <div className="mb-5"></div>
        </div>
    
    </div>    

  </div>
    
        );
    }


export default Exam