import CopyToClipboard from 'react-copy-to-clipboard';
import Styles from './ReferralCard.module.css';
import { ToastContainer } from 'react-toastify';
import { toaster } from '../../functions/O3developer';

function ReferralCard (props) {
const refLink = window.location.origin +'/register?ref='+ props.user.username;
const shareDialogue = () => {
  if (typeof navigator.share === 'function') {
    navigator.share({
      url: refLink
    })
  }
}
  return (
    <div>
      <ToastContainer /> 
{/* style={{ background: "linear-gradient(to right, #FF8100, #D31323 )" }} */}
      {/* <div className={'card card-style mb-1 mt-2 ' + Styles.Rcard} style={{ background: "linear-gradient(rgb(255 255 255 / 85%), rgb(255 255 255 / 94%)), url(/dashboard/images/500naira_note.webp)" }}> */}
      <div className={'card card-style mb-1 mt-2 ' + Styles.Rcard}>
        <div className='content mt-3 mb-2'>
          <div className='d-flex'>
            <div className='me-2'>
              <img src='/dashboard/images/refer-people.png' style={{ width: "50px", height: "40px" }} className='rounded-s shadow-xl' />
            </div>
            <div>
              <h5>
              Refer & Earn
            </h5>

              <p className='mb-0 mt-n2 font-11 font-400' style={{ lineHeight: "19px", wordBreak: 'break-all' }}>
              {refLink}
              </p>

            </div>            

            <div className='ms-auto'>
            <CopyToClipboard text={refLink}
          onCopy={() => toaster("success", "Referral link successfully copied to clipboard")}>
              <a href='#' className='icon icon-s bg-theme shadow-xl rounded-xl float-end' style={{border: "1px dashed #aa8e69"}}>
                <i className='fa color-theme fa-copy' style={{width: "39px", height: "39px"}}></i>                
              </a>
          </CopyToClipboard>
            </div>


          </div>
        </div>



        <hr style={{ marginBottom: "1px", marginTop: "1px", backgroundColor: "#ffffff", border: "0px double #23639a", opacity: "0.7" }} />


        <div className={'text-center font-11 opacity-100 mb-0 pb-2 pt-2 ' + Styles.ReferralTxt} style={{ borderTop: "1px solid rgb(96 125 139)", borderBottom: "1px solid red", borderBottomLeftRadius: "10px", borderTopRightRadius: "10px", }}>
          <i> <b>
               {/* Share out your referral link <i className='fa fa-users' style={{ color: "#ed7a00" }}></i> and 
               enjoy ₦2 on their data 📶 purchase. 
               You will also earn 💰 ₦500 whenever they upgrade 🚀 their 
               account to any of our packages. */}

            Share out your {window.location.hostname} referral link to poeple and earn 💰 {props.commission == "" ? "..." : "₦"+props.commission } immediately 
            the person 🚀 upgrade his/her account to our agent package.

           </b> </i>


           <div style={{ color: '#0050af' }}><b>Commission Balance: &#8358;{props.commission}</b></div>

        </div>





        <div className="d-flex justify-content-between">
            <div>
            <a href={"https://www.facebook.com/sharer/sharer.php?u="+refLink} className="shareToFacebook icon-xl text-center"><i className="fab fa-facebook-f font-14 color-facebook"></i></a>
            </div>
            <div>
            <a href={void(0)} className="shareToTwitter icon-xl text-center"><i className="fab fa-twitter font-14 color-twitter"></i></a>
            </div>
            <div>
            <a href={"whatsapp://send?text=Hello, register on "+ refLink +" to start buying cheap data and airtime today!"} data-action="share/whatsapp/share" className="shareToWhatsApp icon-xl text-center">
              <i className="fab fa-whatsapp font-14 color-whatsapp"></i></a>
            </div>
            <div>
            <a href={void(0)} className="shareToInstagram icon-xl text-center"><i className="fab fa-instagram font-14 color-instagram"></i></a>
            </div>
            <div>
            <a href={void(0)} className="shareToMail icon-xl text-center"><i className="fa fa-envelope font-14 color-mail"></i></a>
            </div>
            <div>
            <a href={void(0)} onClick={() => shareDialogue()} className="shareToCopyLink icon-xl text-center"><i className="fa fa-link font-14 color-dark-dark"></i></a>
            </div>
            </div>



      </div>







    </div>
  )
}


export default ReferralCard;
