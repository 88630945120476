import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import PulseLoader from "react-spinners/PulseLoader";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import LoadingModal from '../components/modals/LoadingModal';
import SetPin from '../components/modals/SetPin';

function Cable () {
const navigate = useNavigate();

  const fetchPlans = async () => {
      dispatch({type: 'start'});
      await axios.post(baseURL+"/cable/plans",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          // console.log(response.data);
          PINHandler(!response.data.pin);
            setValue({
              ...value,
              plans: response.data.plans,
              tv: response.data.cables,
            });
            setWallet(response.data.balance);
            dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }


/**************************************************************************/
const [loading, dispatch] = useReducer(loadingReducer, false);
const [pinModal, setPinModal] = useState(false);
const [wallet, setWallet] = useState('');
const [isValidate, setIsValidate] = useState(false);
const [customerName, setCustomerName] = useState('');
const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState({
        phoneNumber: '',
        cardNumber: '',
        img: '/dashboard/images/MultiChoice_logo.png',
        imgWidth: '35px',
        tv: [],
        plans: [],
        cable: '',
        cablePlans: [],
        selectedPlan: '',
  });


  function tvHandler(e) {
    var cable = e.target.value;
    document.getElementById("plan").selectedIndex = 0;
    if (cable !== "") {
      var plans = value.plans.filter((e) => {
          return e.cable == cable
      })
      setValue({...value, cable: cable,  cablePlans: plans, selectedPlan: []});
    } else {
      setValue({...value, cable: '', cablePlans: [], selectedPlan: []});
    }
  }


/**************************************************************************/

  function planHandler(e) {
    var plan_id = e.target.value;
    var plan = value.cablePlans.filter((e) => {
      return e.id == plan_id;
    });
    setValue({...value, selectedPlan: plan});
  }


/**************************************************************************/

function proceed(e) {
    e.preventDefault();
    if (!validateLoading) {
    if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
    if (value.cable == "") return toaster("error", error.validation.emptyDecoder);
    if (value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
    if (value.cardNumber == '' || value.cardNumber.length < 6) return toaster("error", error.validation.invalidDecoder);
    if (value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber); 
    if (wallet < value.selectedPlan[0].amount && value.selectedPlan[0].amount !== wallet) return toaster("error", error.lowBalance);
     
    if (isValidate == false) { 
        validate();
        setIsValidate(true);
      return;
    }

    if (customerName !== "") {
    let object = {
        "service": "Cable Subscription",
        "id" : value.selectedPlan[0].id,
        "tv" : value.cable,
        "number": value.phoneNumber,
        "amount": value.selectedPlan[0].amount,
        "plan": value.selectedPlan[0].plan,
        "customerName": customerName,
        "cardNumber": value.cardNumber,
    };
    object = JSON.stringify(object);
    if(storage("save", "invoice", object, "sessionStorage")) {
        navigate("/invoice");
    } else {
        return alert(error.whoops);
    }

  } else {
    return toaster("error", error.validation.emptyCustomerName);
  }
  }

}


/**************************************************************************/

function notValidated() {
  setValidateLoading(false);
  setIsValidate(false);
}


function validate() {
  setValidateLoading(true);
  axios.post(baseURL+"/cable/validate", {
    id: value.selectedPlan[0].cable_id,
    card_number: value.cardNumber,
  }, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
    },
  }).then((response) => {
      if (response.data.status == "success" && response.data.name !== "") {
        setCustomerName(response.data.name);   
        setValidateLoading(false);     
        setModalShow(true);
      } else if (response.data.status == "failed" && response.data.message !== "") {
        notValidated();
        return toaster("error", response.data.message);
      } else {
        notValidated();
        return toaster("error", error.whoops);  
      }
    }).catch((e)=>{
        notValidated();
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }      
        return toaster("error", error.whoops);
        // return e;
    })

}

const PINHandler = (value) => {
  setPinModal(value);
}

const hideModal = () => {
  setModalShow(false);
  setIsValidate(false);
}

const btnTxt = isValidate === true ? "Proceed" : "Validate";
const [validateLoading, setValidateLoading] = useState(false);

useEffect(() => {
  scrollToTop();
  fetchPlans();
  return () => axios.CancelToken.source();
},[]);



return (
    <div>

    {/* Top Bar  */}
    <TopBar icon="fas fa-tv"/>

    <div className={"page-content " + Styles.page_content}>


      {/* Top Card */}
    <TopCard wallet={wallet}/>

    <div className="card card-clear" style={{ height: "170px" }}></div>



{/* Confirmation Modal */}

<Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
            <img src="/dashboard/images/Multichoice-banner.png" 
              style={{ width: "70px", height: "70px", borderRadius: "50%" }} 
            /> 
        </div>
        <h4 className='text-center'> {customerName} </h4>      
        <p style={{ marginBottom: "1px" }}>
        <i> Please double-check that the above name corresponds to the card holder's name. </i>
        </p>
        <table className='table table-striped table-bordered' style={{ width:'100%', marginBottom: '0px' }}>
                <tbody>
                    <tr>
                    <td style={{ fontSize: '12px' }}> <b>Card Number</b> </td>
                        <td style={{ fontSize: '12px' }}> <span style={{ color: "blue" }}> <b>  {value.selectedPlan.length == 1 ? value.cardNumber : ''}  </b> </span> </td>

                        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "green" }}><b> ₦{value.selectedPlan.length == 1 ? value.selectedPlan[0].amount : ''} </b></span> </td>
                     </tr>
                    <tr>
                        <th scope='row' style={{ fontSize: '12px' }}> Package </th>
                        <td colSpan='2' style={{ fontSize: '12px' }}> <span style={{ color: "red"}}><b> {value.selectedPlan.length == 1 ? value.selectedPlan[0].plan : ''} </b> </span> </td>
                    </tr>
            </tbody>
        </table>
        <div style={{ marginTop: "20px" }}>
          <Button style={{ float: "left" }} onClick={hideModal} className='mr-auto btn-danger btn-sm'>Cancel</Button>
          <Button style={{ float: "right" }} onClick={proceed}  className='btn-success btn-sm btn-block'>Yes, it's correct</Button>
        </div>

      </Modal.Body>

    </Modal>


<div className={"page-content pb-2 " + Styles.pt_10}>

<ToastContainer/>

<div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>

<div className={"content mb-0 " + Styles.pt_13}>

<div hidden={!loading}>
  <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
        {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
            <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
            </div>
            <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
        </div>

  <div>
    <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
    <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
    <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
  </div>

  
  {loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}

</div>


{/* <LoadingModal text="" key="load" visibility={loading}/> */}

<SetPin visibility={pinModal} state={PINHandler} />

<span hidden={loading}>

<h3><span> Cable Subscription </span>
<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: "2px solid #ffffff"}}>
      <img src={ value.img } className="text-center" width={value.imgWidth} style={{  borderRadius: "0%"  }} />
      </span></h3>
<p className={ Styles.description_txt }>
  Please select TV 📺 and your preferred package from the options below.
</p>

<div className="input-style has-borders no-icon mb-4">

        <select id="tv" onChange={tvHandler} value={value.cable} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.tv.length !== 0 ? value.tv.map((e, index) => { return <option value={e.cable} key={index}> {e.cable} </option> }) : ''}
        </select>

        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>


  <div className="input-style has-borders no-icon mb-4" hidden={ value.cablePlans.length > 0 ? false : true }>
        {/* <label htmlFor="form5" className="color-highlight">Plan</label> */}
        <select id="plan" onChange={ planHandler } className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Select plan</option>
        { value.cablePlans.length !== 0 ? value.cablePlans.map((e, index) => { return <option value={e.id} key={index}> { e.plan }{ " -" } &#8358;{ e.amount } </option> }) : ''}

        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>




  <div className="input-style has-borders has-icon mb-4" hidden={ value.selectedPlan.length > 0 ? false : false }>
    <i className="fa fa-arrow-right" style={{ color: '#ed0010' }}></i>
    <input type="number" autoComplete="off" value={value.cardNumber} onChange={(e) => setValue({...value, cardNumber: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="card_number" placeholder="Enter Card Number" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>




  <div className="input-style has-borders has-icon mb-4" hidden={false}>
    <i className="fa fa-phone" style={{ color: "#008000" }}></i>
    <input type="number" autoComplete="off" value={value.phoneNumber} onChange={(e) => setValue({...value, phoneNumber: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Phone Number" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>




<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>

{ validateLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 { validateLoading ? '' : btnTxt }
  </a>

  </span>




  <div hidden={loading} className="alert rounded-s bg-blue-dark shadow-xl mb-0 mt-1" role="alert" style={{ background: "linear-gradient(45deg, #ff3d00, red)" }}>
<strong className="alert-icon-text color-white" style={{ paddingLeft: "0px" }}>
  <img src="/dashboard/images/all_cable.png" className='shadow-xl' width={25}/> For any complaints, contact GOTV/DSTV customer service at 08039003788 or Startimes customer service at 094618888
  </strong>

  {/* <div hidden={!loading}> <SkeletonTheme height={200} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> </div> */}

<button type="button" className="close color-white opacity-60 font-16" data-bs-dismiss="alert" aria-label="Close">&times;</button>
</div>




            </div>
        </div>
        {/* <div className="mb-5"></div> */}
    </div>








</div>



</div>

    )
}



export default Cable;
