import axios from 'axios';
import { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseURL, user, date, storage, toaster } from '../functions/O3developer';
import PageTopBar from '../components/PageTopBar';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
// import { PulseLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import NoResultFound from './NoResultFound';

function Pricing() {

  const navigate = useNavigate();
  const [dataPlans, setDataPlans] = useState([]);
  const [airtime, setAirtime] = useState([]);
  const [cable, setCable] = useState([]);
  const [cablePlans, setCablePlans] = useState([]);
  const [bills, setBills] = useState([]);  
  const [pagination, setPagination] = useState({
    next_page_url: '',
    prev_page_url: '',
  });
  const [loading, dispatch] = useReducer(loadingReducer, false);
//   const [refLoading, dispatchRefLoading] = useReducer(loadingReducer, false);

  async function load(URL, service) {
    // console.log(typeof(URL));
  if (URL !== null) {
      axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
      dispatch({type: 'start'});
    await axios.post(URL,  {
    }).then((response) => {
      dispatch({type: 'stop'});
        // console.log(response.data.result.data);
      if (response.data.code == 500) {
          return toaster("error", response.data.message);
      } else if (response.data.code == 200) {
          // console.log(response.data);
          switch (service) {
            case 'data': setDataPlans(response.data.plans);
            break;
            case 'airtime': setAirtime(response.data.airtime);
            break;
            case 'cable': setCable(response.data.cables); setCablePlans(response.data.plans);
            break;
            case 'bills': setBills(response.data.bills);
            break;
        }
      }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          
        return toaster("error", error.loading.excemption);
      })
    }
  }



  useEffect(() => {
        load(baseURL+"/data/plans", 'data');
        load(baseURL+"/airtime", 'airtime');
        load(baseURL+"/cable/plans", 'cable');
        load(baseURL+"/bills", 'bills');
    }, []);


function DataPlans(props) {
//   console.log(props.data);
  return (
    <>
        <tr>
            <td style={{ fontSize: '12px' }}> {props.data.id} </td>
            <td style={{ fontSize: '12px' }}> {props.data.network}  </td>
            <td style={{ fontSize: '12px' }}> {props.data.plan}  </td>
            <td scope='row' style={{ fontSize: '12px' }}> ₦{ props.data.amount } </td>
        </tr> 
    </>
  )
}



    function Airtime(props) {
    //   console.log(props.data);
      return (
        <>
            <tr>
                <td style={{ fontSize: '12px' }}> {props.data.id} </td>
                <td style={{ fontSize: '12px' }}> {props.data.network}  </td>
                <td style={{ fontSize: '12px' }}> {props.data.type}  </td>
                <td scope='row' style={{ fontSize: '12px' }}> { props.data.discount }% </td>
            </tr> 
        </>
      )
    }




    function Cables(props) {
        //   console.log(props.data);
          return (
            <>
                <tr>
                    <td style={{ fontSize: '12px' }}> {props.data.id} </td>
                    <td style={{ fontSize: '12px' }}> {props.data.cable}  </td>
                    <td style={{ fontSize: '12px' }}> {props.data.charge_type}  </td>
                    <td scope='row' style={{ fontSize: '12px' }}> 
                    {props.data.charge_type == 'fee' ? '₦' : null} { props.data.charge } {props.data.charge_type == 'discount' ? '%' : null} </td>
                </tr> 
            </>
          )
        }


    function CablePlans(props) {
        //   console.log(props.data);
            return (
            <>
                <tr>
                    <td style={{ fontSize: '12px' }}> {props.data.id} </td>
                    <td style={{ fontSize: '12px' }}> {props.data.cable}  </td>
                    <td style={{ fontSize: '12px' }}> {props.data.plan}  </td>
                    <td scope='row' style={{ fontSize: '12px' }}> ₦{ props.data.amount } </td>
                </tr> 
            </>
            )
        }
    


        function Bills(props) {
          //   console.log(props.data);
            return (
              <>
                  <tr>
                      <td style={{ fontSize: '12px' }}> {props.data.id} </td>
                      <td style={{ fontSize: '12px' }}> {props.data.disco}  </td>
                      <td style={{ fontSize: '12px' }}> {props.data.charge_type}  </td>
                      <td scope='row' style={{ fontSize: '12px' }}> 
                      {props.data.charge_type == 'fee' ? '₦' : null} { props.data.charge } {props.data.charge_type == 'discount' ? '%' : null} </td>
                  </tr> 
              </>
            )
          }




return (
    <div>

<PageTopBar title="Pricing"/>

<div className="page-title-clear"></div>

<ToastContainer/>



<div className='card card-style'>
    <p className='text-center mt-2 mb-2 font-700'> Data </p>
  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b>ID</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> Network </b> </td>
          <td style={{ fontSize: '12px' }}>  <b> Plans </b> </td>
          <td style={{ fontSize: '12px' }}> <b> Amount </b> </td>
        </tr>

        { dataPlans.length !== 0 ? dataPlans.map((data, index) => {
          return <DataPlans key={index} data={data} />
        }) : '' }

      </tbody>
    </table>

<div className='mb-3'>
    { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : null }
    {dataPlans.length == 0 && !loading ? <NoResultFound/> : null }
</div>

  </div>






  <div className='card card-style'>
    <p className='text-center mt-2 mb-2 font-700'> Airtime </p>
  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b>ID</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> Network </b> </td>
          <td style={{ fontSize: '12px' }}>  <b> Type </b> </td>
          <td style={{ fontSize: '12px' }}> <b> Discount </b> </td>
        </tr>

        { airtime.length !== 0 ? airtime.map((data, index) => {
          return <Airtime key={index} data={data} />
        }) : '' }

      </tbody>
    </table>

<div className='mb-3'>
    { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : null }
    {airtime.length == 0 && !loading ? <NoResultFound/> : null }
</div>

  </div>





  <div className='card card-style'>


    <p className='text-center mt-1 mb-2 font-700'> Cable </p>
  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b>ID</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> TV </b> </td>
          <td style={{ fontSize: '12px' }}>  <b> Type </b> </td>
          <td style={{ fontSize: '12px' }}> <b> Charge </b> </td>
        </tr>

        { cable.length !== 0 ? cable.map((data, index) => {
          return <Cables key={index} data={data} />
        }) : '' }

      </tbody>
    </table>

    <div className='mb-3'>
        { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : null }
        {cable.length == 0 && !loading ? <NoResultFound/> : null }
    </div>

{/* ----------------------- */}

    <p className='text-center mt-0 mb-2 font-700'> Cable Plans </p>
  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b>ID</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> TV </b> </td>
          <td style={{ fontSize: '12px' }}>  <b> Plan </b> </td>
          <td style={{ fontSize: '12px' }}> <b> Amount </b> </td>
        </tr>

        { cablePlans.length !== 0 ? cablePlans.map((data, index) => {
          return <CablePlans key={index} data={data} />
        }) : '' }

      </tbody>
    </table>

<div className='mb-3'>
    { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : null }
    {cable.length == 0 && !loading ? <NoResultFound/> : null }
</div>



  </div>






  <div className='card card-style'>


    <p className='text-center mt-1 mb-2 font-700'> Electricity </p>
  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b>ID</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> Disco </b> </td>
          <td style={{ fontSize: '12px' }}>  <b> Type </b> </td>
          <td style={{ fontSize: '12px' }}> <b> Charge </b> </td>
        </tr>

        { bills.length !== 0 ? bills.map((data, index) => {
          return <Bills key={index} data={data} />
        }) : '' }

      </tbody>
    </table>

    <div className='mb-3'>
        { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : null }
        {cable.length == 0 && !loading ? <NoResultFound/> : null }
    </div>

    <div className='mb-3'>
        { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : null }
        {cable.length == 0 && !loading ? <NoResultFound/> : null }
    </div>

  </div>





    </div>
  )
}


export default Pricing;