import React from 'react'
import { useNavigate } from 'react-router-dom'
import { siteName } from '../../functions/O3developer'

function TopBar(props) {
    const navigate = useNavigate();
    return (
      <div>
          
  <div className="header header-fixed header-logo-center">
  <a href={void(0)} className="header-title" style={{ fontFamily: "'Libre Baskerville', serif" }}> {siteName.toUpperCase()} </a>
  {/* <a href={void(0)} onClick={() => navigate("/")} className="header-icon header-icon-1" 
    style={{ background: "repeating-radial-gradient(rgb(0 0 0), #000000 100px)", color: "white", fontWeight: "700", width: "76px", cursor: "context-menu", fontFamily: "'Philosopher', sans-serif", fontSize: "14px" }}>
    <i className="fas fa-chevron-left"></i> Go Back </a> */}
      <a href={void(0)} onClick={() => navigate("/")} className="header-icon header-icon-1">
    <i className="fas fa-chevron-left"></i> </a>
  <a href={void(0)} className="header-icon header-icon-4"><i className={props.icon}></i></a>
  </div>


      </div>
    )

}


export default TopBar