import { Link } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import { backgroundColor, numberWithCommas } from '../../functions/O3developer';

function WalletCard (props) {

  return (
    <div>
      <div className="card-body mx-0 px-0 mt-n2 mb-n5">
      {/* <div className='card card-style ms-3 mb-2' style={{  borderBottomLeftRadius: '0px', borderTopRightRadius: '0px', backgroundImage: 'url(/dashboard/images/card_background.png)', height: '415px' }}> */}
      <div className='card card-style ms-3 mb-2 ms-3' style={{ borderBottomLeftRadius: '0px', borderTopRightRadius: '0px', background: "#930a00", height: '415px' }}>
      {/* <div className='card card-style ms-3 mb-2' style={{ borderBottomLeftRadius: '0px', borderTopRightRadius: '0px', backgroundImage: backgroundColor, height: '415px' }}> */}
        <div className='card-top px-3 py-3'>
          <h1 className='color-white font-20 float-start'>Welcome 👋</h1>
          <Link to='/fund-wallet' style={{ backgroundColor: '#ffc822'}} className='color-black rounded-sm btn btn-xs float-end scale-box font-700 font-12'><i className='fas fa-wallet' style={{ fontWeight: '600' }}></i> Fund Wallet</Link>
        </div>

  {/* Bubbles */}
  <div style={{ background: "#ffffff2b", width: "120px", height: "120px", borderRadius: "50%", position: "relative", top: "-30px", left: "-25px" }}></div>
  <div style={{ background: "#ffffff2b", width: "135px", height: "135px", borderRadius: "50%", position: "relative", top: "-145px", left: "40px" }}></div>
  
        <div className='card-center pe-3' style={{ paddingBottom: "25px", top: "40%" }}>
          <h1 className='color-white text-center font-26' style={{ paddingBottom: '0px', marginBottom: '-4px' }}>
          { props.wallet === "" ? <ScaleLoader color='white'></ScaleLoader> : null }
          { props.wallet !== "" ? "₦" : null }{ props.wallet === "" ? null : numberWithCommas(Math.round(props.wallet * 100) / 100) } 
          {/* <ScaleLoader color={"white"} loading={true} height={30} /> */}
          </h1>
          <p className='color-white opacity-50 font-12 text-center'>
            Wallet Balance
          </p>
        </div>



        <div className='card-right' style={{ background: "#ffffff", width: "120px", height: "120px", borderRadius: "50%", position: "relative", top: "195px", left: "250px" }}></div>

        <div className='card-bottom px-3 py-0'>
          <h5 className='color-white text-start font-19' style={{ marginBottom: '-9px', lineHeight: '0px' }}> { props.user.firstname } </h5>
          <h5 className='color-white text-end font-19'> {props.user.lastname} </h5>
          <div className='divider bg-white opacity-20 mb-1'></div>
          <div className='d-flex'>
            <div className='align-self-center'>
              <img src='/dashboard/images/notification.png' className='rounded-xl' width='25' />
            </div>
            <div className='align-self-center'>
              <span className='ps-3 font-11 opacity-70 font-600 color-white'>
                <marquee direction='left' scrollamount='2'> 
                  {props.notification}
                 </marquee></span>
            </div>
          </div>

          <div className='divider bg-white opacity-20 mb-1'></div>

          <div style={{ marginBottom: "55px", display: "flex", marginBottom: "10px", justifyContent: "space-between" }}>
            <p className='float-start mb-0' style={{ lineHeight: "37px", fontWeight: "bold", color: "white" }}> 
            Status: {props.verification == "true" ? <span className='badge badge-success bg-success'> verified <i className='fas fa-check-circle text-warning'></i> </span> : 
              <Link to={"/kyc"} >  <span className='badge badge-success bg-danger'> not verified <i className='fas fa-exclamation-triangle text-warning'></i> </span> </Link>}

             </p> 

             
            <div className='mt-2'>
            {/* <img src="/dashboard/images/networks.jpg" className="rounded-xl bg-green-dark" width="25"/>
            <img src="/dashboard/images/all_cable.png" className="rounded-xl ms-n3" width="25" height={25}/>
            <img src="/dashboard/images/bulb.png" className="rounded-xl ms-n3" width="25"/> */}

          <img src="/dashboard/images/mtn.png" className="rounded-xl" width="25"/>
          <img src="/dashboard/images/glo.png" className="rounded-xl ms-n2" width="25"/>
          <img src="/dashboard/images/airtel.png" className="rounded-xl ms-n2" width="25"/>
          <img src="/dashboard/images/9mobile.png" className="rounded-xl ms-n2" style={{ background: "#ffffff" }} width="25"/>
          {/* <img src="/dashboard/images/Multichoice-banner.png" className="rounded-xl ms-n2" style={{ background: "#ffffff", borderRadius: '50%' }} width="25" height={25}/> */}
          {/* <img src="/dashboard/images/electricity-power-line.jpg" className="rounded-xl ms-n2" width="25" height={25}/> */}

          {/* <img src="/dashboard/images/mtn.png" className="rounded-xl border border-gray-dark bg-green-dark" width="25"/>
          <img src="/dashboard/images/glo.png" className="rounded-xl border border-gray-dark ms-n3 bg-blue-dark" width="25"/>
          <img src="/dashboard/images/airtel.png" className="rounded-xl border border-gray-dark ms-n3 bg-red-dark" width="25"/>
          <img src="/dashboard/images/9mobile.png" className="rounded-xl border border-gray-dark ms-n3 bg-teal-dark" width="25"/>
          <img src="/dashboard/images/MultiChoice_logo.png" className="rounded-xl border border-gray-dark ms-n3 bg-magenta-dark" width="25" height={25}/>
          <img src="/dashboard/images/bulb.png" className="rounded-xl border border-gray-dark ms-n3 bg-blue-dark" width="25"/> */}
          </div>


            <img src="/images/avatars/5s.png" className='float-end rounded-m' width={35}/>
          </div>


      <div style={{ marginBottom: '5px' }}>
          <div className='divider bg-white opacity-20 mb-1'></div>
          <img src='/dashboard/images/google_play_btn.png' width='155px' style={{ marginLeft: "-10px" }}/>

          <Link to='/upgrade-account' style={{ color: "#fff", border: "1px solid #9e9e9e", marginTop: "8px", padding: "2px 12px", maxWidth: "130px" }} 
            className='rounded-sm btn float-end font-700 font-12'>👉 Become our agent 🚀</Link>
      </div>


        </div>
        <div className='card-overlay bg-gradient opacity-30' style={{ borderRadius: '0px' }}></div>
        <div className='card-overlay bg-gradient' style={{ borderRadius: '0px' }}></div>
      </div>
    </div>
    </div>
  )
}

export default WalletCard
