import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { storage } from '../functions/O3developer';

function PageTopBar(props) {  
    const navigate = useNavigate();
    const logOut = () => {      
      if(storage("remove", "data", null, "localStorage")) {
        return navigate("/login")
      }
  }
  return (
    <div>
        
              
    <div className="page-title page-title-fixed">
        <a href={void(0)} onClick={() => navigate(-1)} className="page-title-icon shadow-xl bg-theme color-theme me-0 ms-3"><i className="fa fa-arrow-left"></i></a>
        <h1 className="font-22"> {props.title} </h1>
        <Link to={'/'} className="page-title-icon shadow-xl bg-theme color-theme"><i className="fab fa-microsoft"></i></Link>
        <a onClick={logOut} className="page-title-icon shadow-xl bg-theme color-theme"><i className="fa fa-power-off"></i></a>
        </div>

    </div>
  )
}

export default PageTopBar
