import React from 'react'

function WhatsappCard(props) {
  return (
    <div>

        
  <div className='card card-style mb-1 mt-2' style={{borderTopLeftRadius: "0px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", background: "dashboard/images/whatsapp.png"}}>
        <div className='content mt-3 mb-3'>
          <div className='d-flex'>

          <div style={{ top: "-15px", position: "absolute", background: "linear-gradient(45deg, #000000b5, transparent)", width: "143px", height: "105px", left: "-21px", borderRadius: "50%" }}> </div>

            <div className='me-2'>
              <img src='dashboard/images/whatsapp.png' style={{ height: '40px', width: '40px' }} className='rounded-s shadow-xl' />
            </div>

            <div>

              <h5> Whatsapp Group </h5>
              <p className='mb-0 mt-n2 font-11 blink' style={{ color: 'green' }}>
                Join our group for updates
              </p>
            </div>
            <div className='ms-auto'>
              <a href={props.link}  className='color-white scale-box rounded-sm btn float-start font-700 font-12' style={{ background: 'linear-gradient(45deg, rgb(14 42 45), rgb(0, 64, 13))', padding: "7px 11px" }}>Join Now 🔥</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WhatsappCard