import React, { useEffect, useReducer, useState } from 'react'
import PageTopBar from '../components/PageTopBar'
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import Styles from './Styles.module.css';
import PulseLoader from "react-spinners/PulseLoader";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


function UpgradeAccount() {
    const navigate = useNavigate();
      // Fetch
  const fetchCategory = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/account-upgrade",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
          // console.log(response.data);
      if (response.data.status == "failed" && response.data.message !== "") {          
          return toaster("error", response.data.message);
        } else if (response.data.status == "success" && response.data.categories.length !== 0) {
                setCategory(response.data.categories)
                setWallet(response.data.balance);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }
      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }



  function proceed() {
    if(!loadingBtn) {
    if(id !== "") {
        dispatchBtn({type: 'start'});
    axios.post(baseURL+"/account/upgrade", {
        id: id,
      },{    
        headers:{
            'Authorization':`Bearer ${user().token}`,
            'Content-Type': 'application/json'
          },
        }).then((response) => {

            if (response.data.status == "success" && response.data.name !== "") {
                dispatchBtn({type: 'stop'});
                return toaster('success', response.data.message);
            } else if (response.data.status == "failed" && response.data.message !== "") {
                dispatchBtn({type: 'stop'});
                return toaster("error", response.data.message);
            } else {
                return toaster("error", error.whoops);  
            }
    
        }).catch((e)=> {
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
          return toaster("error", error.whoops);
            // console.log(e);

        })
    } else {
        return toaster("error", error.validation.requiredInput)
    }

    }

  }

  

  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [loadingBtn, dispatchBtn] = useReducer(loadingReducer, false);
  const [category, setCategory] = useState([]);
  const [id, setID] = useState('');
  const [wallet, setWallet] = useState('');

    useEffect(() => {
        scrollToTop();
        fetchCategory();
        return () => axios.CancelToken.source();
      },[]);

  return (
    <div>
        <ToastContainer/>
        <PageTopBar title="Upgrade"/>

  <div className="page-title-clear"></div>
    <div className="page-content">

    <div className="card dashboard-layout card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
        <div className="content mb-1">




        <div hidden={!loading}>

       <div className='text-center'> <SkeletonTheme height={150} width={"100%"}> <Skeleton count={1} circle={false}/> </SkeletonTheme> </div>

<div className='mt-3'>
  <SkeletonTheme height={10} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
  <SkeletonTheme height={10} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme>
  <SkeletonTheme height={10} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
</div>


<div className='mt-1'>
<SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
<SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
</div>




</div>




<div hidden={loading}>

      <img src='/dashboard/images/become-an-agent_mobile-banner.jpg' style={{ borderRadius: '10px' }} className='mb-3 d-block d-md-none' height="100px" width="100%" />

        <h4 className="mb-1"> Pay less and earn more right away. </h4>

            <p style={{ color: "#393636", marginBottom: '5px' }}>
              Enjoy massive discount on all our services when you upgrade your account to 
              any of the below package
              <br/>

            </p>  


            <div className="input-style has-borders no-icon mb-4">
                <select id="package" onChange={(e) => setID(e.target.value)}>
                <option value=""> Please select  </option>
                { category.length !== 0 ? category.map((e, index) => { return <option value={e.id} key={index}> {e.name} - {e.charge == 'no' ? 'Free' :  '₦' + e.amount} </option> }) : ''}
                </select>
                <span><i className="fa fa-chevron-down"></i></span>
                <i className="fa fa-check disabled valid color-green-dark"></i>
                <i className="fa fa-check disabled invalid color-red-dark"></i>
                <em></em>
            </div>

            <a href={void(0)} type="button" onClick={proceed} disabled={true} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn }>

            { loadingBtn ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
            { loadingBtn ? '' : 'Upgrade' }

            </a>

         </div>

    </div>

  </div>

    </div>


    </div>
  )
}

export default UpgradeAccount