import React from 'react'
import { Modal } from 'react-bootstrap';
import { siteLogo } from '../../functions/O3developer';

function LoadingModal(props) {
  return (
    <div>
    <Modal show={props.visibility} size="lg" aria-labelledby="contained-modal-title-vcenter" animation={false}  centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
          <img style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }} src={siteLogo} height={70} width={"70px"} /> 
        </div>
        <h4 className='text-center'> 
    
        <div className="spinner-border text-success" style={{width:"20px", height: "20px"}} role="status">
        </div>
          <span className='font-18'> Loading... </span> </h4>      
        <p style={{ marginBottom: "1px" }} className="text-center">
        <i> {props.text == "" ? "kindly wait while we gather the resources." : props.text} </i>
        </p>
      </Modal.Body>
    </Modal>



    </div>
  )
}

export default LoadingModal