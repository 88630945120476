import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react'
import { Modal } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { baseURL, scrollToTop, toaster, user, storage} from '../../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import { loadingReducer } from '../../reducers/loading-reducer';
import error from '../../json/error.json';
import { PulseLoader } from 'react-spinners';

function SetPin(props) {
    const navigate = useNavigate();
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [inputPin, setInputPin] = useState('');
    const [pinIcon, setPinIcon] = useState(true);

    function save() {
        
        if(!loading) {
            if(inputPin !== "" && inputPin.length == 4) {
                dispatch({type: 'start'});
            axios.post(baseURL+"/user/set-pin", {
                pin: inputPin,
              },{    
                headers:{
                    'Authorization':`Bearer ${user().token}`,
                    'Content-Type': 'application/json'
                  },
                }).then((response) => {
        
                    if (response.data.status == "success" && response.data.name !== "") {
                        props.state(false);
                        dispatch({type: 'stop'});
                        return toaster('success', response.data.message);
                    } else if (response.data.status == "failed" && response.data.message !== "") {
                        dispatch({type: 'stop'});
                        return toaster("error", response.data.message);
                    } else {
                        return toaster("error", error.whoops);  
                    }
            
                }).catch((e)=> {
                  if (e.response !== undefined) {
                    if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                        storage("remove", "data", null, "localStorage");
                        return navigate("/login");
                    }
                  }
                  return toaster("error", error.whoops);
                    // console.log(e);
        
                })
            } else {
                return toaster("error", error.validation.requiredInput)
            }
        
            }
    }


  return (
    <div>
    <Modal show={props.visibility} size="lg" aria-labelledby="contained-modal-title-vcenter" animation={false}  centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
          <img style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }} src={"/dashboard/images/access-code-icon.png"} height={'90px'} width={"90px"} /> 
        </div>

        <h4 className='text-center'> 
    
        <span className='font-18' style={{ color: "#e00000" }}> Transaction PIN <i className="fas fa-lock" style={{ color: "#3f51b5 " }}></i> </span> </h4>      
        <p style={{ marginBottom: "1px" }} className="text-center">
        <i> Inorder to make your account more secure, you're required to set a 4 digit pin. 
            Any purchase made on our platform will be authorized using this PIN. </i>
        </p>


        <form className="form_pin mb-2 mt-1">
        <PinInput length={4} secret={pinIcon} type="numeric" autoComplete='off' inputMode="number" onChange={(value) => {setInputPin(value)}}/>
        <i onClick={() => {
      var state = !pinIcon;
      setPinIcon(state);
      }} className={pinIcon ? 'fa fa-eye' : 'fas fa-eye-slash'} style={{color: "#cbcbcb", fontSize: "15px", lineHeight: "40px", marginLeft: "10px" }}></i>
        </form>


        <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={void(0)} onClick={save} type="button" className="btn btn-success btn-3d btn-sm btn-m font-600 shadow-bg shadow-bg-m shadow-s ">
            
            { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
            { loading ? '' : 'Set PIN' }

            </a>
        </div>

      </Modal.Body>
    </Modal>



    </div>
  )
}

export default SetPin