import React, { useEffect, useReducer, useState } from 'react'
import { baseURL, scrollToTop, storage, toaster, user} from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Styles from '../Styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';

function Bio() {
    const { state } = useLocation();

    const navigate = useNavigate();
    async function update (e) {
        e.preventDefault();
        if (bio.middleName == "" || bio.gender == "" || bio.dob == "") return toaster("error", error.validation.requiredInput);
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/kyc/complete-profile",  {
                middleName: bio.middleName,
                gender: bio.gender,
                dob: bio.dob,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    setTimeout(() => {
                        navigate("/kyc/process");
                    }, 2000);
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }
            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }
    
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [bio, setBio] = useState({
        middleName: '',
        gender: '',
        dob: ''
    })

    useEffect(() => {
        scrollToTop();
        if (state && state.route !== 'KYC') {         
            return navigate("/kyc/process");
        } else if(state == undefined || state == null) {
            return navigate("/kyc/process");
        }
    }, [])


  return (
    <div>        
        
  <ToastContainer /> 

            <PageTopBar title="Bio Data"/>

        <div className="page-title-clear"></div>
        
        <div className="page-content">

        <div className="card dashboard-layout card-style">

        <div className='content mb-2'>

        <h3> Complete Profile
        <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
            <img src="/dashboard/images/user-icon.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
            </span></h3>
        <p className={ Styles.description_txt }>
            Tell us your birthdate, gender, and middle name so we can celebrate you and get to know you better.
        </p>



        <div className="input-style has-borders has-icon validate-field mb-4">
        <i className="fa fa-arrow-right" style={{ color: "#008000" }}></i>
        <input type="text" value={user().firstname} readOnly autoComplete="off" pattern={'[0-9]*'} className="form-control validate-name" placeholder="First Name" />
        {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(firstname)</em>
        </div>


        <div className="input-style has-borders has-icon validate-field mb-4">
        <i className="fa fa-arrow-right" style={{ color: "#008000" }}></i>
        <input type="text" value={user().lastname} readOnly autoComplete="off" pattern={'[0-9]*'} className="form-control validate-name" id="number" placeholder="Last Name" />
        {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(lastname)</em>
        </div>




        <div className="input-style has-borders has-icon validate-field mb-4 col-lg-12">
        <i className="fas fa-arrow-right"></i>
        <input type="text" value={bio.middleName} onChange={e => setBio({...bio, middleName: e.target.value})} maxLength={"20"} className="form-control" placeholder="Middle Name" autoComplete="off" required />
        <label htmlFor="form1" className="color-highlight">Middle name</label>
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(required)</em>
        </div>



        <div className="input-style has-borders no-icon mb-4">      
            <select id="gender" onChange={(e) => setBio({...bio, gender: e.target.value})}>
            <option value=""> Select Your Gender  </option>
            <option value="male"> Male  </option>
            <option value="female"> Female </option>
            </select>
            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
        </div>



        <span className='font-11' style={{ color:'red' }}><b> Date Of Birth </b></span>

        <div className="input-style has-borders no-icon mb-4">
        <input type="date" onChange={(e) => setBio({...bio, dob: e.target.value})} className="form-control validate-text" id="form6" placeholder="Phone" />
        <label htmlFor="form6" className="color-highlight">Select Date Of Birth</label>
        <i className="fa fa-check disabled valid me-4 pe-3 font-12 color-green-dark"></i>
        <i className="fa fa-check disabled invalid me-4 pe-3 font-12 color-red-dark"></i>           
        </div>


        <a href="#" onClick={update} className="btn btn-full btn-sm rounded-s font-600 font-13 mb-3" style={{ color: "white", backgroundColor: "blue" }}>
        {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Proceed"}  </a>

        </div>

        </div>





    </div>

    </div>
  )
}

export default Bio