import React, { useEffect, useReducer, useState } from 'react'
import { baseURL, scrollToTop, storage, toaster, user} from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Styles from '../Styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import PinInput from 'react-pin-input';

function VerifyEmail() {
    const { state } = useLocation();

    const navigate = useNavigate();

    async function sendOTP (e) {
        e.preventDefault();       
        if (user().email == "") return toaster("error", error.validation.requiredInput);
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/kyc/request-otp",  {
                email: user().email,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    sessionStorage.setItem('otp', true);
                    setOtpRequest(true);
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }

            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }


   

    async function submitOTP (e) {
        e.preventDefault();       
        if (user().email == "" && otp == "") return toaster("error", error.validation.requiredInput);        
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/kyc/verify-otp",  {
                email: user().email,
                otp: otp,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                setTimeout(() => {
                    navigate("/kyc/process");
                }, 2000);
                    sessionStorage.removeItem('otp');
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }

            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }


    function startTimer(duration) {
        let display = document.getElementById('#time_');
        var timer = duration, minutes, seconds;
        sessionStorage.setItem('timer', true);
        const interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);    
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            display.innerHTML = minutes + ":" + seconds;    
            if (--timer < 0) {
                timer = duration;
            }
            if (timer == 0) {
                setCountdown(false);
                sessionStorage.removeItem(timer);
                return clearInterval(interval)
            };

        }, 1000);
    }


    const getOTP = sessionStorage.getItem('otp');
    let otpRequestValue = getOTP !== undefined && getOTP == "true" ? true : false; 
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [otp, setOTP] = useState('');
    const [otpRequest, setOtpRequest] = useState(otpRequestValue);
    const [countdown, setCountdown] = useState(false);

    let onLoadTimer = () => otpRequest ? startTimer(60) : null;

    useEffect(() => {
        scrollToTop();
        // onLoadTimer();
        if (state && state.route !== 'KYC') {         
            return navigate("/kyc/process");
        } else if(state == undefined || state == null) {
            return navigate("/kyc/process");
        }
    }, [])


  return (
    <div>        
        
  <ToastContainer /> 

            <PageTopBar title="Verify Email"/>

        <div className="page-title-clear"></div>
        
        <div className="page-content">


        <div className="card dashboard-layout card-style">

        <div className='content mb-2'>

        <h3> Email Verification 
        <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
            <img src="/dashboard/images/email.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
            </span></h3>
        <p className={ Styles.description_txt }>
        To ensure you actually possess the email you registered, we will send you an OTP.
        </p>


        <div className="input-style has-borders has-icon validate-field mb-4 col-lg-12" hidden={otpRequest}>
        <i className="fas fa-envelope"></i>
        <input type="text" value={user().email} readOnly className="form-control" placeholder="Enter email address" autoComplete="off" required />
        <label htmlFor="form1" className="color-highlight">Account number</label>
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(required)</em>
        </div>
    

        {/* OTP Form */}
        <div className='text-center mb-3' hidden={!otpRequest} style={{ 
                    paddingTop: "20px", 
                    paddingBottom: "20px", 
                    borderRadius: "10px", 
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    background: "#f7be5624"
            }}>
            <div style={{color: "#1f1f1f"}}>Enter OTP <i className='fas fa-arrow-circle-down'></i> </div>
            <PinInput length={6} secret={false} type="numeric" onChange={(value) => setOTP(value)} autoComplete='off' inputMode="number"/>
        </div> 

        {/* Send OTP */}
        <a href="#" hidden={otpRequest} onClick={sendOTP} className="btn btn-full btn-sm rounded-s font-600 font-13 mb-3" 
        style={{ color: "white", backgroundColor: "red" }}>
        {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Send OTP"}  </a>

        {/* Submit OTP  */}
        <a href={void(0)} hidden={!otpRequest} onClick={submitOTP} className="btn btn-full btn-sm rounded-s font-600 font-13 mb-3" 
            style={{ color: "white", backgroundColor: "green" }}>
        {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Submit"}  </a>


            <div hidden={!otpRequest}> 
                <span className='float-start'>Didn't receive OTP ?</span>                
                <span className='float-end'>
                    {countdown ? <span id='time_'></span> : "Resend" }
                </span>
        </div>
        </div>

        </div>



    </div>

    </div>
  )
}

export default VerifyEmail