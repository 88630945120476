import React from 'react'

function NoResultFound() {
  return (
    <div>
        <div className='text-center'>
            <img src='/dashboard/images/no-result-found.png' width={'60%'} />
        </div>
    </div>
  )
}

export default NoResultFound;