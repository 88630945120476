import PageTopBar from '../components/PageTopBar';
import { Link } from 'react-router-dom';


function KYC() {
  
    return (
      <div>

      <PageTopBar title="KYC"/>

  <div className="page-title-clear"></div>
    <div className="page-content">

    <div className="card dashboard-layout card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
        <div className="content mb-1">

      <img src='/dashboard/images/KYC-PROCESS-scaled.png' className='mb-3 d-block d-md-none' height="150px" width="100%" />

        <h4 className="mb-1"> Let's Know You More 😊 </h4>

            <p style={{ color: "#393636", marginBottom: '5px' }}>
              Upon verification, all restrictions on your account will be removed.
              Please be aware that your privacy is the cornerstone of our business, 
              so we will never release your information to a third party unless 
              required to do so by higher authorities, such as a court order. 
              <br/>
              <span>
              <i className="fas fa-exclamation-triangle text-warning"></i> Note that uploading or entering fraudulent data will result in the termination of your account.
              </span> <br/>
                Thanks for choosing us 🤝.
            </p>         

         </div>

         <Link to='/kyc/process' className='color-white mb-3 mt-2 rounded-sm btn scale-box float-start font-700 font-12 m-4' style={{ padding: "12px 14px", backgroundColor: 'black' }}>
            Let's Go <i className='fas fa-arrow-circle-right'></i></Link>

    {/* <span className='text-center mb-3'> <img src='/dashboard/images/finger-pointing-down.png' width="50px" height="65px" /> </span> */}

        </div>




    </div>

      </div>
    )
  
  }
  
  export default KYC;
  