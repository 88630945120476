import React from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../functions/O3developer";

function ProtectedRoute(props) {
  return (
    auth() == true ? <props.Component /> : <Navigate to="/login" />
  );
}

export default ProtectedRoute;
