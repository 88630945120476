import React from 'react'
import { Link } from 'react-router-dom'
import PageTopBar from '../components/PageTopBar'

function NotFound() {
  return (
    <div>
                
<PageTopBar title="Page Not Found"/>

  <div className="page-title-clear"></div>
    <div className="page-content">

    <div className="card dashboard-layout card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
        <div className="content mb-1">

      <img src='/dashboard/images/error-404.png' className='mb-3' height="150px" width="100%" />

    <p><i> We're sorry, the page you were looking for isn't found here.
        The link you followed may either be broken or no longer exists. 
        Please try again, or take a look at our. </i></p>
         </div>

    <div className='mb-3' style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
    <Link to={"/"} type="button" style={{ backgroundColor: "red", width: "40%" }} 
      className={"btn btn-sm btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s" }>
        <i className='fas fa-arrow-circle-left'></i> Go Home
    </Link>
    </div>
        </div>

    </div>


    </div>
  )
}

export default NotFound