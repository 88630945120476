import { useEffect, useReducer, useState } from 'react';
import Styles from '../Styles.module.css';
import { baseURL, storage, toaster, user } from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import { loadingReducer } from '../../reducers/loading-reducer';
import error from '../../json/error.json';
import PulseLoader from "react-spinners/PulseLoader";

function Coupon() {
    const navigate = useNavigate();

    const [code, setCode] = useState('');
    var [attempt, setAttempt] = useState(0);
    const [loading, dispatch] = useReducer(loadingReducer, false);  
    
    async function proceed(e) {
        e.preventDefault();        
        if (code == "") return toaster("error", "Please enter coupon code");        
        if (!loading){
            dispatch({type: 'start'});   
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/fund-wallet/coupon",  {
                code: code,
                attempt: attempt,
          }).then((response) => {
            dispatch({type: 'stop'});
            if (response.data.status == "failed" && response.data.message !== "") {
                 setAttempt(attempt+1);
                return toaster("error", response.data.message);
              } else if (response.data.status == "success") {
                return toaster("success", response.data.message);                              
                  } else {
                    return toaster("error", error.whoops);    
                  }
      
            }).catch((e) => {
                if (e.response !== undefined) {
                    if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                        storage("remove", "data", null, "localStorage");
                        return navigate("/login");
                    }
                  }
                  console.log(e);
                    return toaster("error", error.loading.excemption);
            }) 
        }       
    }



    return (
      <div>
  
  
      <PageTopBar title="Coupon Funding" />

  {/* <div className="mb-5"></div> */}

  
  <div className="page-title-clear"></div>
    <div className="page-content">


  <ToastContainer /> 

  <div className="card dashboard-layout card-style">
<div className="content mb-0">
<h3>Fund With Coupon

<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
      <img src="/dashboard/images/Coupons.jpg" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
      </span></h3>
<p className={ Styles.description_txt }>
    Enter your coupon code in the field below to get your account credited.
</p>




<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-arrow-right" style={{ color: 'green' }}></i>
<input type="text" maxLength={100} value={code} onChange={(e) => setCode(e.target.value)} className="form-control validate-name shadow-bg-m shadow-s" id="form1ab" placeholder="Enter Coupon" />
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed} >
    
{ loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loading ? '' : 'Proceed' }
    
     </a>

    </div>

   
<div className='text-center mt-2 mb-3'>
    <img src='/dashboard/images/coupon.png' width={'120'}/>
</div>

</div>
</div>
  
  
  </div>
  
    )
  
  }
  
  export default Coupon;
  