import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage, siteName} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
// import LoadingModal from '../components/modals/LoadingModal';


function RechargePin() {
    const navigate = useNavigate();
    
      const fetchPlans = async () => {
          dispatch({type: 'start'});
          await axios.post(baseURL+"/recharge-pin",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
        }).then((response) => {
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
              // console.log(response.data);
              PINHandler(!response.data.pin);
                  setValue({
                    ...value,
                    recharge: response.data.recharge,
                    range: response.data.range,
                  });
                  setWallet(response.data.balance);
                  dispatch({type: 'stop'});
              } else {
                return toaster("error", error.loading.excemption);    
              }
    
          }).catch((e) => {
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
              // return e;
          })
      }
        
    /**************************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [wallet, setWallet] = useState('');
      const [value, setValue] = useState({
            img: '/dashboard/images/printer.png',
            imgWidth: '35px',
            recharge: [],
            range: [],
            network: '',
            selectedNetwork: [],
            selectedRange: '',
            quantity: '',
            amount: '',
            name: ''
      });
   
      function networkHandler(e) {
        var network = e.target.value;
        document.getElementById("range").selectedIndex = 0;
        if (network !== "") {
          var networks = value.recharge.filter((e) => {
              return e.id == network
          });
          // console.log(networks);          
          setValue({...value, network: network,  selectedNetwork: networks, selectedRange: '', quantity: ''});
        } else {
          setValue({...value, network: '', selectedNetwork: [], selectedRange: '', quantity: ''});
        }
      }
    
    
    /**************************************************************************/

      function rangeHandler(e) {
        let range = e.target.value;
        if (range !== '') {                        
            setValue({...value, selectedRange: range, amount: '', quantity: ''});
        } else {
          setValue({...value, selectedRange: '', amount: '', quantity: ''});
        }
      }


      function quantityHandler(e) {
        if (value.selectedRange !== '') {
            let quantity = e.target.value;  
            if (quantity < 61) {
              let price = value.selectedRange;
              let amount = price * quantity;
              quantity !== '' ? setValue({...value, quantity: quantity, amount: amount}) : setValue({...value, quantity: '', amount: ''});
            }
        } 
      }


    /**************************************************************************/
    
    function proceed(e) {
      e.preventDefault();
      if (value.selectedNetwork.length !== 1) return toaster("error", error.validation.emptyNetwork);
      if (value.selectedRange == "") return toaster("error", error.validation.emptyQuantity);
      if (value.quantity == "") return toaster("error", error.validation.emptyQuantity);
      if (value.name == "") return toaster("error", error.validation.emptyCardName);      
      if (value.quantity == "" || value.quantity == 0) return toaster("error", error.validation.emptyQuantity);
      if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);

      let object = {
          "service": "Recharge Pin",
          "id" : value.selectedNetwork[0].id,
          "network" : value.selectedNetwork[0].network,
          "quantity": value.quantity,
          "amount": value.amount,
          "range": value.selectedRange,
          "name": value.name,
        };
      object = JSON.stringify(object);
      if(storage("save", "invoice", object, "sessionStorage")) {
          navigate("/invoice");
      } else {
          return alert(error.whoops);
      }
    
    }
    

    const PINHandler = (value) => {
      setPinModal(value);
    }  
  
    useEffect(() => {
      scrollToTop();
      fetchPlans();
      return () => axios.CancelToken.source();
    },[]);
    
    return (
        <div>

          {/* Top Bar  */}
      <TopBar icon="fas fa-barcode"/>      
    
        <div className={"page-content " + Styles.page_content}>
    
    
        <TopCard wallet={wallet} />


    <div className="card card-clear" style={{ height: "170px" }}></div>
    
                
    <div className={"page-content pb-4 " + Styles.pt_10}>    
    
    <ToastContainer/>

    <div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>
    
    
    <div className={"content mb-0 " + Styles.pt_13}>

    <SetPin visibility={pinModal} state={PINHandler} />

  <div hidden={!loading}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>


      <div>
        <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme>       
    </div>

    {loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}

  </div>
    



    <span hidden={loading}>

    <h3><span> Recharge Card Printing </span>
    
    <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: ""}}>
          <img src={ value.img } className="text-center" width={value.imgWidth} style={{  borderRadius: "50%"  }} />
          </span></h3>
    <p className={ Styles.description_txt }>
    
    Kindly select a network below and input the quantity you need. 
    Max quantity per page is 60.
    
    </p>
    

    {/* <LoadingModal text="" key="load" visibility={loading}/> */}


    <div className="input-style has-borders no-icon mb-4">

        <select id="network" onChange={networkHandler} value={value.network} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.recharge.length !== 0 ? value.recharge.map((e, index) => { return <option value={e.id} key={index}> {e.network} </option> }) : ''}
        </select>

            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
      </div>
    

    
    <div className="input-style has-borders no-icon mb-4" hidden={ value.selectedNetwork.length == 1 ? false : true }>
        {/* <label htmlFor="form5" className="color-highlight">Plan</label> */}
        <select id="range" onChange={ rangeHandler } className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Select range</option>
        { value.selectedNetwork.length == 1 ? value.range.map((e, index) => {
          if (e.id == value.selectedNetwork[0].id) {
            return <option value={e.range} key={index}> &#8358;{e.range} </option>
          }
        }) : '<option value=""> Service currently unavailable </option>'  }

        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>
      



  <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.selectedRange !== '' ? false : true }>
    <i className="fas fa-print" style={{ color: "#ff0000" }}></i>
    <input type="number" autoComplete="off" value={value.quantity} onChange={ quantityHandler } className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Type qunatity [max 60]" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
    </div>
    
 
  <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.quantity !== '' ? false : true }>
    {/* <i className="fa fa-money" style={{ color: "#000094" }}></i> */}
    <i className="fas fa-money-bill-alt"></i>
    <input type="text" readOnly autoComplete="off" value={value.amount} onChange={ (e) => {setValue({...value, amount: e.target.value})} } className="form-control validate-name shadow-bg-m shadow-s" id="amount" placeholder="Enter amount" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    {/* <em>(required)</em> */}
    </div>





      <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.quantity !== '' ? false : true }>
    <i className="fas fa-arrow-right" style={{ color: "#008000" }}></i>
    <input type="text" autoComplete="off" maxLength={20}  value={value.name} onChange={(e) => setValue({...value, name: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Name on card [E.g your name or bussiness name]" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
    </div>

    


    <a href="#" className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>
        Proceed </a>
    
        </span>
    
    
    
    
                </div>
            </div>
            <div className="mb-5"></div>
        </div>
    
    </div>
    
    
    </div>
    
        );
    }


export default RechargePin