import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import WalletCard from "../components/dashboard/WalletCard"
// import SummaryCard from "../components/dashboard/SummaryCard"
import AgentCard from "../components/dashboard/AgentCard";
// import VirtualAccount from "../components/dashboard/VirtualAccount";
// import PlayCard from '../components/dashboard/PlayCard';
import Notification from "../components/dashboard/Notification";
import ReferralCard from '../components/dashboard/ReferralCard';
import Services from "../components/dashboard/Services";
import { baseURL, scrollToTop, toaster, user, storage, siteName, sweetalert} from '../functions/O3developer';
// import Social from '../components/Social';
import WhatsappCard from '../components/WhatsappCard';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FloatingWhatsApp from 'react-floating-whatsapp';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';

// import SweetAlert from 'react-bootstrap-sweetalert';
import Footer from "../components/Footer";

function Dashboard () {
  useEffect(() => {

    var body = document.getElementById("body-tag");
    body.classList.remove("auth-bg");

    scrollToTop();
      AOS.init();
      dashboardRequest();
      
   } , []);



  const navigate = useNavigate();
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [wallet, setWallet] = useState('');  
  const [commission, setCommission] = useState('');
  const [verification, setVerification] = useState('');
  const [notification, setNotification] = useState('');
  const [whatsappNo, setWhatsappNo] = useState('');
  const [whatsappLink, setWhatsappLink] = useState('');
  const [bonus, setBonus] = useState('');

  const dashboardRequest = async () => {
      dispatch({type: 'start'});
      await axios.post(baseURL+"/user/dashboard",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          // return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
            setWallet(response.data.user.wallet);
            setCommission(response.data.user.commission);
            setVerification(response.data.user.verification);
            setNotification(response.data.notification.message);
            setBonus(response.data.referral.commission);
            setWhatsappNo(response.data.whatsapp.number);
            setWhatsappLink(response.data.whatsapp.link);
            if (response.data.notification.message !== null) {
              sweetalert(response.data.notification.message);              
            }
        }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          // return toaster("error", error.loading.excemption);
          // return e;
      })
  }

  const navBarHandler = () => {
    var main_menu = document.getElementById("menu-main");
    main_menu.classList.add("menu-active");
    // remove nav opacity
    var menu_hider = document.getElementById("o3");
    menu_hider.classList.add("menu-active");
  }


  return (
    <div>
      <div className="dashboard-layout">





    {/* <div className="page-content"> */}
    <div className="card notch-clear rounded-0" style={{ height: '265px', marginBottom: '260px', backgroundImage: 'linear-gradient(to right, rgb(40 40 40), rgb(41 41 41), rgb(76 76 76 / 88%)), url(/dashboard/images/all-4g-LTE-data-plans-in-Nigeria.webp)' }}>
    <div className="card-body pt-4">
    <div className="d-flex">
    <div className="align-self-center">      
    <h1 className="color-white font-24 float-start mb-0"> <img src="/dashboard/images/AzaraVTU-BG-logo.png" width={"140"} /> </h1>
    </div>
    <div className="ms-auto align-self-center">
    <a href={void(0)} onClick={navBarHandler} className="bg-theme icon icon-xs font-600 rounded-sm color-theme"><i className="fa fa-bars"></i></a>
    </div>
    </div>
    </div>
    <WalletCard user={user()} wallet={wallet} notification={notification} verification={verification} />
    {/* <div className="card-body mx-0 px-0 mt-n2 mb-n5">
    <div className="card card-style ms-3 mb-n5" style={{ backgroundImage: "url(images/health/10m.jpg);", height: '300px'}}>
    <div className="card-top m-2">
    <a href="tel:+1 234 567 8991" className="default-link btn btn-s bg-theme color-theme rounded-sm text-uppercase font-700 float-end">Call Now</a>
    </div>
    <div className="card-bottom px-3 py-3">
    <h1 className="color-white font-24 mb-0">Here when it Matter</h1>
    <p className="mb-0 color-white pe-5 opacity-60">
    You can always count on us to be there for you when you need it most.
    </p>
    </div>
    <div className="card-overlay bg-gradient opacity-30"></div>
    <div className="card-overlay bg-gradient"></div>
    </div>
    </div> */}
    </div>








        {/* <Notification message={notification} /> */}
        {/* <WalletCard user={user()} wallet={wallet} notification={notification} verification={verification}/> */}
        {/* <SummaryCard commission={commission}/>          */}
        {/* <AgentCard/> */}        
        {/* <VirtualAccount/> */}
        {/* <Social mt="9px" mb="9px"/> */}
        {/* <PlayCard/> */}
        <ReferralCard user={user()} commission={commission}/>
        {/* <WhatsappCard link={whatsappLink} /> */}
        <Services/>
        {/* <Footer /> */}
    </div>

        <FloatingWhatsApp
            phoneNumber={whatsappNo !== '' ? whatsappNo : null}
            accountName={siteName}
            statusMessage="Customer care representative"
            avatar = "/dashboard/images/avater.png"
            chatMessage="Hello, What can I do to assist you?"
            allowClickAway
            // notification
            // notificationDelay={60000} // 1 minute
            // notificationSound
        />


    </div>

  )
}

export default Dashboard
