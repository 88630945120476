import React, { useEffect, useReducer, useState } from 'react'
import { baseURL, scrollToTop, storage, toaster, user} from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Styles from '../Styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';

function UploadID() {
    const { state } = useLocation();

    const navigate = useNavigate();

    function handleNIN(e) {
        setUpload({...upload, nin_slip: e.target.files[0]});
    }

    async function update (e) {
        e.preventDefault();    
        if (upload.address == "" || upload.stateOfOrigin == "" || upload.nin == "" || upload.nin == "" || upload.nin_slip == "") return toaster("error", error.validation.requiredInput);
        if(!loading) {
            const formData = new FormData();
            formData.append("nin_slip", upload.nin_slip);
            formData.append("nin", upload.nin);
            formData.append("address", upload.address);
            formData.append("stateOfOrigin", upload.stateOfOrigin);
            // console.log(formData.get('nin'));
            dispatch({type: 'start'});
            await axios.post(baseURL+"/user/kyc/upload-id",  formData,                        
            {    
                headers:{
                    'Authorization':`Bearer ${user().token}`,
                    'Content-Type': 'multipart/form-data'
                }, 
            }
                
            ).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    setTimeout(() => {
                        navigate("/kyc/process");
                    }, 2000);
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }
            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }
    
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [upload, setUpload] = useState({
        address: '',
        stateOfOrigin: '',
        nin: '',
        nin_slip: ''
    })

    useEffect(() => {
        scrollToTop();
        if (state && state.route !== 'KYC') {         
            return navigate("/kyc/process");
        } else if(state == undefined || state == null) {
            return navigate("/kyc/process");
        }
    }, [])


  return (
    <div>        
        
  <ToastContainer /> 

            <PageTopBar title="Upload ID"/>

        <div className="page-title-clear"></div>
        
        <div className="page-content">


        <div className="card dashboard-layout card-style">

        <div className='content mb-2'>

        <h3> Upload NIN 
        <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
            <img src="/dashboard/images/kyc-slip.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
            </span></h3>
        <p className={ Styles.description_txt }>
        Upload your NIN slip and tell us your residential address.
        </p>




        <div className="input-style has-borders has-icon validate-field mb-4 col-lg-12">
        <i className="fas fa-address-book"></i>
        <input type="text" value={upload.address} onChange={e => setUpload({...upload, address: e.target.value})} maxLength={"250"} className="form-control" placeholder="Home Address" autoComplete="off" required />
        <label htmlFor="form1" className="color-highlight">Home Address</label>
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(required)</em>
        </div>



        <div className="input-style has-borders no-icon mb-4">      
            <select id="bank" onChange={(e) => setUpload({...upload, stateOfOrigin: e.target.value})}>
            <option value=""> Select your state of origin  </option>
            <option value="Abuja FCT">Abuja FCT</option>
              <option value="Abia">Abia</option>
              <option value="Adamawa">Adamawa</option>
              <option value="Akwa Ibom">Akwa Ibom</option>
              <option value="Anambra">Anambra</option>
              <option value="Bauchi">Bauchi</option>
              <option value="Bayelsa">Bayelsa</option>
              <option value="Benue">Benue</option>
              <option value="Borno">Borno</option>
              <option value="Cross River">Cross River</option>
              <option value="Delta">Delta</option>
              <option value="Ebonyi">Ebonyi</option>
              <option value="Edo">Edo</option>
              <option value="Ekiti">Ekiti</option>
              <option value="Enugu">Enugu</option>
              <option value="Gombe">Gombe</option>
              <option value="Imo">Imo</option>
              <option value="Jigawa">Jigawa</option>
              <option value="Kaduna">Kaduna</option>
              <option value="Kano">Kano</option>
              <option value="Katsina">Katsina</option>
              <option value="Kebbi">Kebbi</option>
              <option value="Kogi">Kogi</option>
              <option value="Kwara">Kwara</option>
              <option value="Lagos">Lagos</option>
              <option value="Nassarawa">Nassarawa</option>
              <option value="Niger">Niger</option>
              <option value="Ogun">Ogun</option>
              <option value="Ondo">Ondo</option>
              <option value="Osun">Osun</option>
              <option value="Oyo">Oyo</option>
              <option value="Plateau">Plateau</option>
              <option value="Rivers">Rivers</option>
              <option value="Sokoto">Sokoto</option>
              <option value="Taraba">Taraba</option>
              <option value="Yobe">Yobe</option>
              <option value="Zamfara">Zamfara</option>
            </select>
            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
        </div>


        <div className="input-style has-borders has-icon validate-field mb-4 col-lg-12">
        <i className="fas fa-arrow-right"></i>
        <input type="number" value={upload.nin} onChange={e => setUpload({...upload, nin: e.target.value})} maxLength={"20"} className="form-control" placeholder="Enter NIN " autoComplete="off" required />
        <label htmlFor="form1" className="color-highlight">Home Address</label>
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(required)</em>
        </div>


        <div className='pt-2 mb-3' 
            style={{ border: "2px dashed green", 
                    paddingLeft: "20px", 
                    borderRadius: "10px", 
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    background: "antiquewhite"
            }}>

        <span style={{ color: "red" }}>Upload your NIN Slip</span>

        <div className=" has-borders no-icon mb-4">          
        <input type="file" onChange={handleNIN} />
        </div>

        </div>

        <a href="#" onClick={update} className="btn btn-full btn-sm rounded-s font-600 font-13 mb-3" style={{ color: "white", backgroundColor: "blue" }}>
        {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Submit"}  </a>

        </div>

        </div>


    </div>

    </div>
  )
}

export default UploadID