import axios from 'axios';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseURL, user, date, storage, toaster, siteLogo } from '../functions/O3developer';
import styles from './Transaction.module.css'
import error from '../json/error.json';
import { ToastContainer } from 'react-toastify';
import PageTopBar from '../components/PageTopBar';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useReducer } from 'react';
import { loadingReducer } from '../reducers/loading-reducer';
import NoResultFound from './NoResultFound';

function Transaction () {

  const navigate = useNavigate();
  const [history, setHistory] = useState([]);  
  const [pagination, setPagination] = useState({
    next_page_url: '',
    prev_page_url: '',
  });

  const [entries, setEntries] = useState('');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(true);
  const [loading, dispatch] = useReducer(loadingReducer, false);

  async function load(URL) {
    if (URL !==  null) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
    dispatch({type: 'start'});
    await axios.get(URL,  {
      }).then((response) => {
        dispatch({type: 'stop'});
        // console.log(response.data);
        setHistory(response.data.data);
        setPagination({...pagination, 
          next_page_url: response.data.next_page_url,
          prev_page_url: response.data.prev_page_url,
        });
      if (response.data.code == 500) {
        return toaster("error", response.data.message);
          // return toaster("error", response.data.message);
      } else if (response.data.code == 200) {
          // console.log(response.data);
      }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          
        return toaster("error", error.loading.excemption);
      })
    }
  }


  const viewReceipt = (id) => {
      let transaction = history.find((e) => e.id == id);
      // console.log(transaction);
      if(storage("save", "receipt", transaction, "sessionStorage")) {
        navigate("/receipt");
      } else {
          // return alert(error.whoops);
      }
  }
  

  const go = (e) => {
    e.preventDefault();
    load(baseURL + "/transaction?search="+search+"&entries="+entries);
  }

  const filterState = (event) => {
    event.preventDefault();
    // setFilter(!filter);
  }




  useEffect(() => {
    load(baseURL+"/transaction?search="+search+"&entries="+25);    
  }, []);


function TranxStatus(props) {
  let badgeClass = null;
  switch(props.status) {  
    case "success": badgeClass = "bg-green";
    break;
    case "reversed": badgeClass = "bg-red";
    break;
    case "cancel": badgeClass = "bg-red";    
    break;
    case "pending": badgeClass = "bg-warning";
     break;
    case "initiated": badgeClass = "bg-warning";
    break;
    default: badgeClass = "bg-warning";
  }
  return (
    <>
      <span className={"mt-0 badge border-0 " + badgeClass}>{props.status}</span>
    </>
  )

}


function Item(props) {
    // console.log(props.data);    
    let imgBaseURL = "/dashboard/images/";
    let tranxImg = siteLogo;
    const provider = props.data.provider;
    const service = props.data.service;
    if (provider == "MTN") {
      tranxImg = imgBaseURL + "mtn.png";
    } else if (provider == "GLO") {
      tranxImg = imgBaseURL + "glo.png";
    } else if (provider == "AIRTEL") {
      tranxImg = imgBaseURL + "airtel.png";      
    } else if (provider == "9MOBILE") {
      tranxImg = imgBaseURL + "9mobile.png";
    } else if (service == "Bills Payment") {
      tranxImg = imgBaseURL + "bulb.png";
    } else if (service == "Result Checker") {
      tranxImg = imgBaseURL + "graduation-cap.png";
    } else if (service == "Cable Subscription") {
      tranxImg = imgBaseURL + "multichoice.jpg";
    } else if (service == "Wallet Funding") {
      tranxImg = imgBaseURL + "wallet-icon.png";
    } else if (service == "Spectranet") {
      tranxImg = imgBaseURL + "spectranet-device.png";
    } else if (service == "SMS") {
      tranxImg = imgBaseURL + "sms-icon.png";
    } else if (service == "Referral") {
      tranxImg = imgBaseURL + "exchange.png";
    }

    return (
      <>

<div className="d-flex">
<div className="me-auto">
<img src={tranxImg} width="40" className="rounded-radius" />
</div>
<div className="w-100 ps-2">
<h4 className="mb-0 font-14 mb-1 tranx_service_font" style={{  lineHeight: '18px' }}> {props.data.service} </h4>
<p className="mb-0 font-12 font-500" style={{ lineHeight: '18px', wordBreak: 'break-all' }}> {props.data.description} </p>
<p className='mb-0'>
<i className="fas fa-wallet color-yellow-dark icon-10 text-center me-1 font-11"></i> 
  <span className='font-12'> Initial Bal: <span style={{ color: 'brown' }}><b>₦{Math.round(props.data.balance_before * 100) / 100}</b></span> | Final Bal: <span style={{ color: 'purple' }}><b> ₦{Math.round(props.data.balance_after * 100) / 100} </b></span> </span>
</p>
</div>
</div>


<table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
      <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}>                    
           <TranxStatus status={props.data.status}></TranxStatus>
              {/* <span className="mt-0 badge border bg-green">{props.data.status}</span> */}
          
          </td>
        <td style={{ fontSize: '12px' }}> <span className='color-highlight'> <b> {props.data.type} </b> </span> </td>
        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "green" }}><b> ₦{Math.round(props.data.amount * 100) / 100} </b></span> </td>
      </tr>

      <tr>
          <td colSpan='2' style={{ fontSize: '12px' }}> <span style={{ color: "red"}}><b> 
            <span className='font-11'> 
          { date(props.data.date) }
        </span> </b> </span> </td>
          <th scope='row' style={{ fontSize: '12px' }}>  
          <span className="ms-2 badge border color-blue-dark border-blue-dark" style={{backgroundColor: '', cursor: "context-menu"}} onClick={() => viewReceipt(props.data.id)}>View Receipt </span> </th>
      </tr>


      <tr hidden={ props.data.remark !== "null" && props.data.remark.length > 4 ? false : true }>
          <td colSpan='3' style={{ fontSize: '12px' }}> 
            <span className='tranx_service_font'> { props.data.remark }  </span>           
          </td>
      </tr>


    </tbody>
  </table>

      </>
    )
  }




return (
    <div>

<PageTopBar title="Transactions"/>

<div className="page-title-clear"></div>
    <div className="page-content">

    <div className="card card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>

<div className="content mb-4">

<div className="row mb-0">
<div className="col-5">

<a href="#" onClick={(e) => filterState(e)} className="btn btn-full btn-m rounded-s font-600" style={{ border: '1px solid #e00000', color: '#002a72' }}> Filters <i className="fas fa-filter"></i> </a>
</div>

<div className="col-7">
<div className="input-style has-borders no-icon input-style-always-active mb-4">
<select id="form5" style={{ height: '49px' }} value={entries} onChange={ (e) => setEntries(e.target.value) }>
<option value="25">25</option>
<option value="50">50</option>
<option value="100">100</option>
<option value="150">150</option>
<option value="200">200</option>
</select>
<span><i className="fa fa-chevron-down"></i></span>
<i className="fa fa-check disabled valid color-green-dark"></i>
<i className="fa fa-check disabled invalid color-red-dark"></i>
<em></em>
</div>
</div>
</div>



<div className="row mb-0" hidden={filter}>

  <div className="col-4">
    <div className="input-style has-borders no-icon input-style-always-active mb-4">
      <select id="form5" style={{ height: '49px' }} value={entries} onChange={ (e) => setEntries(e.target.value) }>
      <option value="">Sort By</option>
      <option value="ASC">Ascending</option>
      <option value="DESC">Descending</option>
      </select>
    </div>
  </div>

  <div className="col-4">
    <div className="input-style has-borders no-icon input-style-always-active mb-4">
      <select id="form5" style={{ height: '49px' }} value={entries} onChange={ (e) => setEntries(e.target.value) }>
      <option value="">Service</option>
      <option value="Data Topup">Data</option>
      <option value="Airtime Topup">Airtime</option>
      <option value="Bills Payment">Electricity</option>
      <option value="Result Checker">Exam</option>
      </select>
    </div>
  </div>

</div>


<div className="search-box search-dark shadow-sm border-0 mt-0 mb-2 bg-theme rounded-sm bottom-0">
<i className="fa fa-search ms-1"></i>
<input type="text" value={search} maxLength="100" onChange={(e) => setSearch(e.target.value)} className="border-0" placeholder="Searching for something? Type it here" />
</div>

<a href="#" className="btn btn-full btn-m rounded-s font-600" onClick={(e) => go(e)} style={{ backgroundColor: '#800000' }}> Go <i className='fas fa-arrow-circle-right'></i> </a>

</div>
</div>


<ToastContainer/>






<div className={'card dashboard-layout card-style p-3 mt-2' + ' ' + styles.Vcard} >
<div className="">


{/* -------------------------------------- */}

  { history.length !== 0 && !loading ? history.map((data, index) => {
   return <Item key={index} data={data} />
  }) : '' }

{/* ------------------------------------ */}


{history.length == 0 && !loading ? <NoResultFound/> : null }

<div hidden={!loading}>

{/* Loading skeleton */}
<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
  <div><SkeletonTheme height={65} width={65}> <Skeleton count={1} circle={true}/> </SkeletonTheme></div>

    <div style={{ marginLeft: '5px' }}>
      <SkeletonTheme height={20} width={120}> <Skeleton  count={1}/> </SkeletonTheme> 
      <SkeletonTheme height={20} width={230}> <Skeleton  count={1}/> </SkeletonTheme>
      <SkeletonTheme height={10} width={230}> <Skeleton  count={1}/> </SkeletonTheme>
    </div>
  </div>



  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
      <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}>                    
          <SkeletonTheme height={20} width={70}> <Skeleton  count={1}/> </SkeletonTheme>                       
          </td>
        <td style={{ fontSize: '12px' }}> <span className='color-highlight'> <SkeletonTheme height={20} width={70}> <Skeleton  count={1}/> </SkeletonTheme>  </span> </td>
        <td style={{ fontSize: '12px' }}> <SkeletonTheme height={20} width={70}> <Skeleton  count={1}/> </SkeletonTheme> </td>
      </tr>

      <tr>
          <td colSpan='2' style={{ fontSize: '12px' }}>        
            <SkeletonTheme height={20} width={160}> <Skeleton  count={1}/> </SkeletonTheme>
         </td>
          <th scope='row' style={{ fontSize: '12px' }}>  
          <SkeletonTheme height={20} width={70}> <Skeleton  count={1}/> </SkeletonTheme> </th>
      </tr>


    </tbody>
  </table>
</div>
{/* Loading skeleton ends here */}




<div hidden={history.length == 0 ? true : false}>
<nav aria-label="pagination-demo">
<ul className="pagination pagination- justify-content-center mb-0">

<li className="page-item">
<a hidden={pagination.prev_page_url == null ? true : false} onClick={() => load(pagination.prev_page_url) } style={{ cursor: "context-menu" }} className="page-link rounded-xs color-black bg-transparent bg-theme shadow-xl border-0" href={void(0)} tabIndex={1} aria-disabled="true"><i className="fa fa-angle-left"></i> Previous</a>
</li>


{/* <li className="page-item"><a className="page-link rounded-xs color-black bg-theme shadow-l border-0" href="#">1</a></li> */}
{/* <li className="page-item"><a className="page-link rounded-xs color-black bg-theme shadow-l border-0" href="#">2</a></li> */}
{/* <li className="page-item active"><a className="page-link rounded-xs color-black bg-highlight shadow-l border-0" href="#">3</a></li> */}
{/* <li className="page-item"><a className="page-link rounded-xs color-black bg-theme shadow-l border-0" href="#">4</a></li> */}
{/* <li className="page-item"><a className="page-link rounded-xs color-black bg-theme shadow-l border-0" href="#">5</a></li> */}

<li className="page-item">
<a hidden={pagination.next_page_url == null ? true : false} onClick={() => load(pagination.next_page_url) } style={{ cursor: "context-menu", color: "white" }} className="page-link rounded-xs  bg-highlight shadow-l border-0" href={void(0)}> Next <i className="fa fa-angle-right"></i> </a>
</li>

</ul>
</nav>
</div>
</div>

</div>


</div>


<div style={{ marginBottom: '50px' }}></div>


    </div>

  )
}

export default Transaction
