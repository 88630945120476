import React, { Component } from 'react'

class Bubbles extends Component {
  render() {
    return (
      <div>

    <section className="sticky">
    <div className="bubbles">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>

    </div>
</section>   
          
      </div>
    )
  }
}


export default Bubbles;