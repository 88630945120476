import axios from 'axios';
import { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseURL, user, date, storage, toaster } from '../functions/O3developer';
import PageTopBar from '../components/PageTopBar';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { PulseLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import NoResultFound from './NoResultFound';

function Referral() {

  const navigate = useNavigate();
  const [referral, setReferrals] = useState([]);  
  const [pagination, setPagination] = useState({
    next_page_url: '',
    prev_page_url: '',
  });
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [commission, setCommission] = useState('');
  const [count, setCount] = useState('');
  const [refLoading, dispatchRefLoading] = useReducer(loadingReducer, false);

  async function load(URL) {
    // console.log(typeof(URL));
  if (URL !== null) {
      axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
      dispatch({type: 'start'});
    await axios.post(URL,  {
    }).then((response) => {
      dispatch({type: 'stop'});
        // console.log(response.data.result.data);
        setReferrals(response.data.result.data);
        setCommission(response.data.commission);
        setCount(response.data.count);
        setPagination({...pagination, 
          next_page_url: response.data.result.next_page_url,
          prev_page_url: response.data.result.prev_page_url,
        });
      if (response.data.code == 500) {
          return toaster("error", response.data.message);
      } else if (response.data.code == 200) {
          // console.log(response.data);
      }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          
        return toaster("error", error.loading.excemption);
      })
    }
  }



function withdraw(e) {
    e.preventDefault();          
    dispatchRefLoading({type: 'start'});
    axios.post(baseURL+"/commission/withdraw",  {
      type: 'withdrawal',
  }, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
    }, 
  }).then((response) => {
        // console.log(response.data);
      if (response.data.status == "failed" && response.data.message !== "") {
          dispatchRefLoading({type: 'stop'});
          return toaster("error", response.data.message);
      } else if (response.data.status == "success") { 
          dispatchRefLoading({type: 'stop'});
          setCommission(0);
          return toaster("success", response.data.message);                      
          } else {
            return toaster("error", error.loading.excemption);    
          }
    }).catch((e) => {   
      if (e.response !== undefined) {
        if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
            storage("remove", "data", null, "localStorage");
            return navigate("/login");
        }
      }
        return toaster("error", error.loading.excemption);
        // return e;
    })

  }



  useEffect(() => load(baseURL+"/referral/log"), []);


function Item(props) {
  // console.log(props.data);
  return (
    <>
        <tr>
            <td style={{ fontSize: '12px' }}> {props.data.username} </td>
            <td style={{ fontSize: '12px' }}> {props.data.email}  </td>
            <td scope='row' style={{ fontSize: '12px' }}> { date(props.data.dateTime) } </td>
        </tr> 
    </>
  )
}



return (
    <div>

<PageTopBar title="Referral"/>

<div className="page-title-clear"></div>

<ToastContainer/>

<div className="card card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
    <div className="content">

      { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : 
        <p style={{ color: "#393636", marginBottom: '5px' }} hidden={loading}>
       
  <div className='row mb-0'>

  <p className='mb-0 font-13 font-600'>Commission</p>

  <div className="col-6">
        <div className="input-style has-borders no-icon input-style-always-active mb-4">
        <div className="col-lg-12 input-style has-borders has-icon validate-field mb-4">
        <i className="fa fa-gift" style={{ color: "blue" }}></i>
        <input type="text" style={{ height: '47px' }} readOnly autoComplete="off" value={commission} className="form-control" id="commssion" placeholder="Commission" />
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
    </div>

        </div>
      </div>

       <div className="col-6">
          <a href="#" onClick={(e) => withdraw(e)} className="btn btn-full btn-m rounded-s font-600" style={{ border: '1px solid #795548', color: '#795548' }}> 
          { refLoading ? <PulseLoader color={"#795548"} loading={true} size={8} /> : '' }
          { refLoading ? '' : "Withdraw "  }
          { refLoading ? '' : <i className='fas fa-wallet'></i>  }

          </a>
      </div>
    </div>     

    <span style={{ color: 'green' }}><b> Total Referred User: {count} </b></span>

       </p> }
      
    </div>
    </div>


<div className='card card-style'>
  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b>Username</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> Email </b> </td>
          <td style={{ fontSize: '12px' }}> <b> Date Joined </b> </td>
        </tr>

        { referral.length !== 0 ? referral.map((data, index) => {
          return <Item key={index} data={data} />
        }) : '' }

        {referral.length == 0 && !loading ? <NoResultFound/> : null }

      </tbody>
    </table>


  <div className='mb-3' hidden={referral.length == 0 ? true : false}>
    <nav aria-label="pagination-demo">
      <ul className="pagination pagination- justify-content-center mb-0">

      <li className="page-item">
      <a onClick={() => load(pagination.prev_page_url) } style={{ cursor: "context-menu" }} className="page-link rounded-xs color-black bg-transparent bg-theme shadow-xl border-0" href={void(0)} tabIndex={1} aria-disabled="true"><i className="fa fa-angle-left"></i> Previous</a>
      </li>

      <li className="page-item">
      <a onClick={() => load(pagination.next_page_url) } style={{ cursor: "context-menu", color: "white" }} className="page-link rounded-xs  bg-highlight shadow-l border-0" href={void(0)}> Next <i className="fa fa-angle-right"></i> </a>
      </li>

      </ul>
    </nav>
  </div>

  </div>



    </div>
  )
}


export default Referral;