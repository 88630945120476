import { Link } from "react-router-dom";
import { baseURL, logOff, siteName, user } from "../functions/O3developer";

const sideBarHandler = () => {
  var main_menu = document.getElementById("menu-main");
  main_menu.classList.remove("menu-active");
  // remove nav opacity
  var menu_hider = document.getElementById("o3");
  menu_hider.classList.remove("menu-active");
}


function SideBar () {

  return (
    <div>
      <div
        id='menu-main'
        className='menu menu-box-left rounded-0'
        data-menu-width='280'
        data-menu-active='nav-pages'>
        <div className='card rounded-0 bg-6' style={{ height: '150px' }}>
          <div className='card-top'>
            <a href='#' className='close-menu float-end me-2 text-center mt-3 icon-40 notch-clear'><i className='fa fa-times color-white'></i></a>
          </div>
          <div className='card-bottom'>
            <h1 className='color-white ps-3 mb-n1 font-28'>{ siteName }</h1>
            <p className='mb-2 ps-3 font-12 color-white opacity-50'>
              Navigation Menu
            </p>
          </div>
          <div className='card-overlay bg-gradient'></div>
        </div>
        <div className='mt-4'></div>
        <h6 className='menu-divider'>GO TO 👇</h6>
        <div className='list-group list-custom-small list-menu'>

          <Link id='nav-welcome' to='/' onClick={sideBarHandler}><i className='fab fa-microsoft gradient-red color-white'></i> <span>Home</span> <i className='fa fa-angle-right'></i></Link>

          <Link to='/transaction' onClick={sideBarHandler}><i className='fab fa-opencart gradient-teal color-white'></i> <span>Transaction</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/data' onClick={sideBarHandler}><i className='fa fa-wifi gradient-green color-white'></i> <span>Buy Data</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/airtime' onClick={sideBarHandler}><i className='fa fa-phone gradient-blue color-white'></i> <span>Buy Airtime</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/cable' onClick={sideBarHandler}><i className='fa fa-tv gradient-brown color-white'></i> <span>Cable Subscription</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/electricity' onClick={sideBarHandler}><i className='fa fa-lightbulb gradient-magenta color-white'></i> <span>Electricity Payment</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/recharge-pin' onClick={sideBarHandler}><i className='fa fa-envelope gradient-teal color-white'></i> <span>Recharge Card Pin</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/exam' onClick={sideBarHandler}><i className='fa fa-envelope gradient-orange color-white'></i> <span>Exam Result Pin</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/spectranet' onClick={sideBarHandler}><i className='fas fa-layer-group gradient-green color-white'></i> <span>Spectranet</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/smile' onClick={sideBarHandler}><i className='fas fa-smile gradient-blue color-white'></i> <span>Smile</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/airtime-cash' onClick={sideBarHandler}><i className='fas fa-dollar-sign gradient-brown color-white'></i> <span>Airtime To Cash</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/bulk-sms' onClick={sideBarHandler}><i className='fa fa-sms gradient-magenta color-white'></i> <span>Bulk SMS</span> <i className='fa fa-angle-right'></i></Link>
        </div>

        <h6 className='menu-divider mt-4'>Account</h6>
        <div className='list-group list-custom-small list-menu'>
          <Link to='/profile' onClick={sideBarHandler}><i className='fa fa-user gradient-highlight color-white'></i> <span>Profile</span> <i className='fa fa-angle-right'></i></Link>
          <Link to='/reset-pin' onClick={sideBarHandler}><i className='fa fa-key gradient-orange color-white'></i> <span> Reset PIN </span> <i className='fa fa-angle-right'></i></Link>                    
        </div>


        <h6 className='menu-divider mt-4'>Referral</h6>
        <div className='list-group list-custom-small list-menu'>
          <Link to='/referral/log' onClick={sideBarHandler}><i className='fa fa-users gradient-highlight color-white'></i> <span>Log/Earnings</span> <i className='fa fa-angle-right'></i></Link>
        </div>


        <h6 className='menu-divider mt-4'>Developers</h6>
        <div className='list-group list-custom-small list-menu'>
          <a href={baseURL+'/api/documentation'}><img src='/dashboard/images/api.png' /> <span>API Documentation</span> <i className='fa fa-angle-right'></i></a>
          <Link to={'/pricing'} onClick={sideBarHandler}> <i className='fa fa-code gradient-brown color-white'></i> <span>Product Code / Pricing</span> <i className='fa fa-angle-right'></i></Link>
          </div>


          <div className='list-group list-custom-small list-menu'>
          <a href={void(0)} onClick={logOff}> <i className='fa fa-power-off gradient-red color-white'></i> <span>Log Out</span> <i className='fa fa-angle-right'></i></a>
          </div>

        {/* <h6 className='menu-divider font-10 mt-4'>Made with <i className='fa fa-heart color-red-dark pl-1 pr-1'></i> by O3Developers </h6> */}
      </div>
    </div>
  )
}

export default SideBar
