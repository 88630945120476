import React, { useEffect, useReducer } from 'react'
import { useState } from 'react';
import Styles from './../Styles.module.css';
import { baseURL, scrollToTop, storage, toaster, user} from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import { Link, useNavigate } from 'react-router-dom';
import { loadingReducer } from '../../reducers/loading-reducer';
// import LoadingModal from '../../components/modals/LoadingModal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function Process() {
    const navigate = useNavigate();
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [status, setStatus] = useState({
      profile: '',
      email: '',
      bank: '',
      nin: '',
      bvn: '',
    })
    const loadData = async () => {
        dispatch({type: 'start'});
        await axios.post(baseURL+"/user/kyc/process",  {
      }, {    
        headers:{
            'Authorization':`Bearer ${user().token}`,
            'Content-Type': 'application/json'
        }, 
    }).then((response) => {
            // console.log(response.data);
          if (response.data.status == "failed" && response.data.message !== "") {
            // return toaster("error", response.data.message);
          } else if (response.data.status == "success") {
            dispatch({type: "stop"});
            const data = response.data.data;
            setStatus({...status, profile: data.profile, email: data.email, bank: data.bank, nin:  data.nin, bvn: data.bvn});
            
          }
        }).catch((e) => {   
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            // return toaster("error", error.loading.excemption);
            // return e;            
        })
    }
  

    function SkeletonCard(props) {
    
      return (
        <>
        <a href={void(0)} className="d-flex mb-2">
          <div style={{ marginRight: '5px' }}><SkeletonTheme height={50} width={50}> <Skeleton count={1} circle={true}/> </SkeletonTheme></div>
        <div>
        <h5 className="font-16 font-600">
          <SkeletonTheme height={12} width={220}> <Skeleton  count={1}/> </SkeletonTheme>
        </h5>
          <p className="line-height-s mt-1 opacity-70" style={{ fontSize: '12px' }}> 
            <SkeletonTheme height={10} width={220}> <Skeleton  count={2}/> </SkeletonTheme>                        
          </p>
        </div>
        <div className="align-self-center ps-3">
        <i className="fa fa-spinner fa-spin opacity-20"></i>
        </div>
        </a>

          <div className="divider mb-3"></div>
        </>
      )
    }
    


useEffect(() => {
  loadData();
}, [])

  const arr = [1,2,3,4,5];
    
  return (
    <div>

        <PageTopBar title="Verification"/>

        <ToastContainer /> 


        <div className="page-title-clear"></div>
        <div className="page-content">

{/* Loading card */}
    <div hidden={!loading} className={"card dashboard-layout card-style " + Styles.kyc_card_border } style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
      <div className="content mb-0">
        {arr.map(() => <SkeletonCard/>)} 
      </div>
    </div>
{/* Loading card ends */}




    <div hidden={loading} className={"card dashboard-layout card-style " + Styles.kyc_card_border } style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
    <div className="content mb-0">

    {/* <LoadingModal text="Please wait while fetching verification data." key="load" visibility={loading}/> */}


    <a onClick={() => status.profile == "" ? navigate("/kyc/complete-profile", { 
            state: { route: "KYC" }  
          }) : null} style={{ cursor: "context-menu" }} href={void(0)} className="d-flex mb-2">
    <div>
    <img src="/dashboard/images/user-icon.png" height="50" width="50" className="rounded-xl me-3" />
    </div>
    <div>
    <h5 className="font-16 font-600">Complete your profile</h5>
    <p className="line-height-s mt-1 opacity-70" style={{ fontSize: '12px' }}> 
    Tell us your birthdate, gender, and middle name ...        
          </p>
    </div>
    <div className="align-self-center ps-3">
    {status.profile == "" ? <i className="fa fa-angle-right opacity-20"></i> : <i className="fa fa-check-circle opacity-20" style={{ color: 'green' }}></i> }
    </div>
    </a>



    <div className="divider mb-3"></div>

    <a onClick={() => status.email == "" ? navigate("/kyc/verify-email", { 
            state: { route: "KYC" }  
          }) : null} style={{ cursor: "context-menu" }} href={void(0)} className="d-flex mb-2">
    <div>
    <img src="/dashboard/images/email.png" width="50" className="rounded-xl me-3" />
    </div>
    <div>
    <h5 className="font-16 font-600">Email Address Verification</h5>
    <p className="line-height-s mt-1 opacity-70"  style={{ fontSize: '12px' }}> 
    To ensure you actually possess the email you registered, we will send you an OTP. 
    </p>
    </div>
    <div className="align-self-center ps-3">
    {status.email == "" ? <i className="fa fa-angle-right opacity-20"></i> : <i className="fa fa-check-circle opacity-20" style={{ color: 'green' }}></i> }
    </div>
    </a>


    
    <div className="divider mb-3"></div>
    <a onClick={() => status.bank == "" ? navigate("/kyc/bank-account", { 
            state: { route: "KYC" }  
          }) : null} style={{ cursor: "context-menu" }} href={void(0)} className="d-flex mb-2">
    <div>
    <img src="/dashboard/images/Banks.webp" height="50" width="50" className="rounded-xl me-3" />
    </div>
    <div>
    <h5 className="font-16 font-600">Bank Account </h5>
    <p className="line-height-s mt-1 opacity-70"  style={{ fontSize: '12px' }}> 
        Save your bank account for withdrawals and other record-keeping purposes. </p>
    </div>
    <div className="align-self-center ps-3">
    {status.bank == "" ? <i className="fa fa-angle-right opacity-20"></i> : <i className="fa fa-check-circle opacity-20" style={{ color: 'green' }}></i> }
    </div>
    </a>



    <div className="divider mb-3"></div>
    <a onClick={() => status.nin == "" ? navigate("/kyc/upload-id", { 
            state: { route: "KYC" }  
          }) : null} style={{ cursor: "context-menu" }} href={void(0)} className="d-flex mb-2">
    <div>
    <img src="/dashboard/images/flag-round-nigeria.png" width="50" className="rounded-xl me-3" />
    </div>
    <div>
    <h5 className="font-16 font-600">Upload ID [NIN Slip] / Address</h5>
    <p className="line-height-s mt-1 opacity-70" style={{ fontSize: '12px' }}> 
          Upload your NIN slip and tell us your home residential address.
    </p>
    </div>
    <div className="align-self-center ps-3">
      {status.nin == "" ? <i className="fa fa-angle-right opacity-20"></i> : <i className="fa fa-check-circle opacity-20" style={{ color: 'green' }}></i> }
    </div>
    </a>



    <div className="divider mb-3"></div>
    <a onClick={() => status.bvn == "" ? navigate("/kyc/bvn-verification", { 
            state: { route: "KYC" }  
          }) : null} style={{ cursor: "context-menu" }} href={void(0)} className="d-flex mb-3">
    <div>
    <img src="/dashboard/images/bvn_logo.png" width="50" className="rounded-xl me-3" />
    </div>
    <div>
    <h5 className="font-16 font-600"> BVN Verification </h5>
    <p className="line-height-s mt-1 opacity-70" style={{ fontSize: '12px' }}> 
    To verify that the given account number belongs to you, we have to check your BVN. 
	</p>
    </div>
    <div className="align-self-center ps-3">
    {status.bvn == "" ? <i className="fa fa-angle-right opacity-20"></i> : <i className="fa fa-check-circle opacity-20" style={{ color: 'green' }}></i> }
    </div>
    </a>

    </div>
</div>

</div>


    </div>
  )
}

export default Process