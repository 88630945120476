import { useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, storage, toaster, user} from '../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import PageTopBar from '../components/PageTopBar';

function ResetPIN() {

    const navigate = useNavigate();
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [state, setState] = useState({
        password: '',
        pin: '',
    });  
     
    async function proceed(e) {
        e.preventDefault();
        if (state.password == "" || state.pin == "") return toaster("error", error.validation.requiredInput);
        if (state.pin.length !== 4) return toaster("error", "PIN must not be more or less than 4 digits.");
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/reset-pin",  {
                password: state.password,
                pin: state.pin,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }
            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }



  return (
    <div>


  {/* <div className="mb-5"></div> */}

  <PageTopBar title="Change PIN"/>

<div className="page-title-clear"></div>
  <div className="page-content">


<ToastContainer /> 

<div className="card dashboard-layout card-style">
<div className="content mb-0">
<h3> Reset PIN

<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
    <img src="/dashboard/images/access-code-icon.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
    </span></h3>
<p className={ Styles.description_txt }>
    Change your transaction pin by typing your current password and new PIN.
</p>


<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-arrow-right" style={{ color: 'red' }}></i>
<input type="password" onChange={(e) => setState({...state, password: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="password" placeholder="Enter your new password" />
{/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>





<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-key" style={{ color: 'green' }}></i>
<input type="number" onChange={(e) => setState({ ...state, pin: e.target.value })} className="form-control validate-name shadow-bg-m shadow-s" id="pin" placeholder="Enter your PIN" />
{/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<a href={void(0)} disabled={loading} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s mb-4 " + Styles.proceedBtn } onClick={proceed} >
 {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Proceed"}  </a>



</div>
</div>


</div>


    </div>
  )
}

export default ResetPIN