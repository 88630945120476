import React, { useReducer } from 'react'
import Styles from './Invoice.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { useEffect, useState } from 'react';
import PinInput from 'react-pin-input';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import error from '../json/error.json';
import PulseLoader from "react-spinners/PulseLoader";
import { loadingReducer } from '../reducers/loading-reducer';
import { ToastContainer } from 'react-toastify';
import Bubbles from '../components/Bubbles';
import TopBar from '../components/service/TopBar';
import TranxLimitModal from '../components/modals/TranxLimitModal';


function Invoice() {

const navigate = useNavigate();

function invoice() {
  let data = storage("retrieve", "invoice", null, 'sessionStorage');
  if (data && typeof(data) === "object") {    
        setDetails(data);
        // console.log(data);
        data.number && setNumberArray(data.number.split(','));
        // console.log(data.number.split(','));  
  } else {
    navigate("/");
  }

}

  const [details, setDetails] = useState({});
  const [pinIcon, setPinIcon] = useState(true);
  const [inputPin, setInputPin] = useState('');
  const [verifyModal, setVerifyModal] = useState(false);
  const [numberArray, setNumberArray] = useState([]);
  const [loading, dispatch] = useReducer(loadingReducer, false);

  const sendRequest = async (payload, path) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
    let response = await axios.post(baseURL+path, payload)
      .then((response) => {
          return response.data;
      }).catch((e)=>{

        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }

          // console.log(e);
          return e;
      })
      return response;
  }

  const proceed = async (e) =>  {
    e.preventDefault();
    if (!loading) {
      let pin = inputPin.trim();
      if (pin.length == 4) {
        // Data Topup
          if (details.service == "Data Topup") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, number: details.number, }
            let response = await sendRequest(payload, "/data/topup");
            dispatch({type: 'stop'});
              if (response.code == 200) {                                    
                  storage("remove", "invoice", null, "sessionStorage");
                  let mobileNumberArray = details.number.split(',');
                  let route = "/receipt";
                  if (mobileNumberArray.length > 1) {
                     route = "/transaction";
                  }
                  return storage("save", "receipt", response, "sessionStorage") ? navigate(route) : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return response.code !== 426 ? toaster("error", response.message) : setVerifyModal(true);
              } else {
                return alert(error.whoops);
              }

            // Airtime Topup
          } else if (details.service == "Airtime Topup") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined || details.amount == "" 
            || details.amount == undefined) return toaster("error", error.Oops);
              dispatch({type: 'start'});
              let payload = { id: details.id, pin: pin, number: details.number, amount: details.amount,}
              let response = await sendRequest(payload, "/airtime/topup");
              dispatch({type: 'stop'});
              if (response.code == 200) {                
                storage("remove", "invoice", null, "sessionStorage");
                
                let mobileNumberArray = details.number.split(',');
                let route = "/receipt";
                if (mobileNumberArray.length > 1) {
                   route = "/transaction";
                }
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              }
                
                // Cable subscription
            } else if (details.service == "Cable Subscription") {
              if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined || details.cardNumber == "" 
              || details.cardNumber == undefined) return toaster("error", error.Oops);
              dispatch({type: 'start'});
              let payload = { id: details.id, pin: pin, number: details.number, cardNumber: details.cardNumber }
              let response = await sendRequest(payload, "/cable/subscription");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              }

              // Bills Payment
          } else if (details.service == "Bills Payment") {
              if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined 
                || details.meterNumber == "" || details.meterNumber == undefined || details.amount == "" || 
                details.amount == undefined || details.type == "" || details.type == undefined) return toaster("error", error.Oops);
                dispatch({type: 'start'});
              let payload = { id: details.id, pin: pin, number: details.number, type: details.type, meterNumber: details.meterNumber, amount: details.amount, }            
              let response = await sendRequest(payload, "/bills/payment");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              }

              // Smile Bundle
          } else if (details.service == "Smile Bundle") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined || details.smileNumber == "" || details.smileNumber == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, number: details.number, smileNumber: details.smileNumber, }            
            let response = await sendRequest(payload, "/smile/topup");
            dispatch({type: 'stop'});
            if (response.code == 200) {
              storage("remove", "invoice", null, "sessionStorage");
              return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
            } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.message);
            } else {
              return alert(error.whoops);
            } 
            

          } else if (details.service == "Result Checker") {
            if (details.id == "" || details.id == undefined || details.quantity == "" || details.quantity == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, quantity: details.quantity, }            
            let response = await sendRequest(payload, "/exam/pin");
            dispatch({type: 'stop'});
            if (response.code == 200) {
              storage("remove", "invoice", null, "sessionStorage");
              return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
            } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.message);
            } else {
              return alert(error.whoops);
            } 


          } else if (details.service == "Recharge Pin") {
            if (details.id == "" || details.id == undefined || details.quantity == "" || details.quantity == undefined
            || details.range == "" || details.range == undefined || details.name == "" || details.name == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, range: details.range, quantity: details.quantity, name: details.name, }            
            let response = await sendRequest(payload, "/recharge-pin/purchase");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              } 

          } else if (details.service == "Airtime To Cash") {
            if (details.id == "" || details.id == undefined || details.mode == "" || details.mode == undefined
            || details.amount == "" || details.amount == undefined || details.value == "" || details.value == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, mode: details.mode, amount: details.amount, value: details.value, }            
            let response = await sendRequest(payload, "/airtime-to-cash/convert");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              } 
          } else if (details.service == "Spectranet") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined
            || details.amount == "" || details.amount == undefined || details.plan == "" || details.plan == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, number: details.number}            
            let response = await sendRequest(payload, "/spectranet/pin");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              } 
          }


      } else {
        return toaster("error", 'Please input your transaction pin.');
      }
    }

  }


  useEffect(() => {
    scrollToTop();
    invoice();
  }, []);



  return (
    <div>


        <div id="page">
    <div className="page-content">


<TopBar icon="fas fa-file-invoice" />



  <div className={"card card-fixed bg-6 data_info_card " + Styles.card_style }>

		<div className={"card-top p-3 mt-1 " + Styles.card_top }>
            <h4 className={"font-700 color-white font-15 pt-1 text-center " + Styles.h4_color}>Thanks for choosing us 🤝 </h4>

		</div>


		<div className="card-overlay bg-gradient" style={{ borderBottomLeftRadius: "50px", borderBottomRightRadius: "50px" }}></div>
		<div className="card-overlay bg-black opacity-75" style={{ borderBottomLeftRadius: "50px", borderBottomRightRadius: "50px" }}></div>
    </div>
    <div className="card card-clear" style={{height: "135px"}}></div>


<TranxLimitModal key="load" visibility={verifyModal}/>

<div className="page-content pb-4">

<Bubbles/>


<ToastContainer /> 


         {/* card in this page format must have the class card-full to avoid seeing behind it */}
<div className={"card card-full rounded-m pb-1 " + Styles.invoice_card} >



<div className="content mb-2" style={{ margin: "12px 15px 20px 15px" }}>


<div className="d-flex">
<div className="align-self-center pe-3">
 <img src="/dashboard/images/warning-circle.png" width="45px" style={{ borderRadius: "50%", border: "2px solid #ffc107", padding: "2px" }} />
</div>
<div className="align-self-center">
<h4 className="text-center">Order Confirmation</h4>
<p className="line-height-s" style={{ fontSize: "12px", color: "rgb(31, 31, 31)" }}>
 Before you proceed, please double-check the transaction you're about to make.
</p>

</div>
</div>

<div className="divider w-100 mt-2 mb-0"></div>


{/* service */}

<div className="list-group list-custom-small">
<a href={void(0)} style={{ color: "#000" }}>
<i className={"fab font-14 fa-opencart color-brown-dark rounded-sm shadow-xl " + Styles.service_icon}></i>
<span>Service</span>
<span className={"badge " + Styles.service_text}> {details.service} </span>
{/* <i className="fa fa-angle-right"></i> */}
</a>



{/* network */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.service == "Cable Subscription" || details.service == "Bills Payment" 
|| details.service == "Smile Bundle" || details.service == "Result Checker" || details.service == "Spectranet"  ? true : false}>
    <i className={"fas fa-mobile-alt color-blue-dark font-14 rounded-sm shadow-xl " + Styles.network_style}></i>
{/* <i className="fa font-14 fa-bars color-blue-dark"></i>
<img src="/dashboard/images/networks.jpg" style="border-radius: 50%; width: 20px; height: 20px"/> */}
<span>Network</span>
<span className={"badge bg-red-dark font-10 " + Styles.network_txt}>{details.network}</span>
<i className="fa fa-angle-right"></i>
</a>



{/* plan / type */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.type !== undefined || details.plan !== undefined  ? false : true}>
    <i className="far fa-arrow-alt-circle-right font-14 rounded-sm shadow-xl" style={{ color: "#ffffff", backgroundColor: "#AA8E69" }}></i>
<span>{ details.service == "Data Topup" || details.service == "Cable Subscription" || details.service == "Smile Bundle" || details.service == "Spectranet"  ? "Plan" : "Type" }</span>
<span className={"badge " + Styles.plan_txt }> { details.service == "Data Topup" || details.service == "Cable Subscription" || details.service == "Smile Bundle" || details.service == "Spectranet"  ? details.plan : details.type }  </span>
{/* <i className="fa fa-angle-right"></i>  */}
</a>



{/* Exam */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.exam !== undefined ? false : true}>
    <i className="fas fa-graduation-cap font-14 rounded-sm shadow-xl" style={{ color: "#ffffff", backgroundColor: "#4a89dc" }}></i>
<span>Exam</span>
<span className={"badge " + Styles.plan_txt }> {details.exam}  </span>
{/* <i className="fa fa-angle-right"></i>  */}
</a>



{/* Quantity */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.quantity !== undefined ? false : true}>
    <i className="far fa-arrow-alt-circle-right font-14 rounded-sm shadow-xl" style={{ color: "#ffffff", backgroundColor: "#AA8E69" }}></i>
<span>Quantity</span>
<span className={"badge " + Styles.plan_txt }> {details.quantity}  </span>
{/* <i className="fa fa-angle-right"></i>  */}
</a>

{/* Mode */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.mode !== undefined ? false : true}>
    <i className="far fa-arrow-alt-circle-right font-14 rounded-sm shadow-xl" style={{ color: "#ffffff", backgroundColor: "#AA8E69" }}></i>
<span>Mode</span>
<span className={"badge " + Styles.plan_txt }> {details.mode}  </span>
{/* <i className="fa fa-angle-right"></i>  */}
</a>


{/* Name */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.name == "" || details.name == undefined ? true : false}>
 <i className={"fa font-14 fa-user rounded-sm shadow-xl " + Styles.customer_name_icon }></i>
<span>Name</span>
<span className={"badge " + Styles.phone_number_txt}> { details.name } </span>
{/* <i className="fa fa-angle-right"></i> */}
</a>


{/* Amount */}
<a href={void(0)} style={{ color: "#000" }} className={numberArray.length == 0 ? 'border-0' : ''}>
<i className={"fab fa-amazon-pay font-14 rounded-sm shadow-xl " + Styles.amount_icon}></i>
<span>Amount</span>
<span className={"badge " + Styles.amount_txt}> &#8358;{ numberArray.length !== 0 ? details.amount * numberArray.length : details.amount } </span>
<i className="fa fa-angle-right"></i>
</a>


{/* Customer name */}
<a href={void(0)} style={{ color: "#000" }} hidden={details.customerName == "" || details.customerName == undefined ? true : false}>
 <i className={"fa font-14 fa-user rounded-sm shadow-xl " + Styles.customer_name_icon }></i>
<span>Customer Name</span>
<span className={"badge " + Styles.phone_number_txt}> { details.customerName } </span>
{/* <i className="fa fa-angle-right"></i> */}
</a>





{/* phone number */}

<a href={void(0)} style={{ color: "#000" }} className="border-0" hidden={numberArray.length == 0 ? true : false }>
 <i className={"fa font-14 fa-phone rounded-sm shadow-xl " + Styles.phone_number_icon }></i>
<span>Phone Number</span>
<span className={"badge " + Styles.phone_number_txt}> { numberArray.length == 1 ? details.number : numberArray.length + ' Numbers' } </span>
{/* <i className="fa fa-angle-right"></i> */}
</a>


  <form className="form_pin">

    <span style={{color: "#1f1f1f"}}>Enter Pin: </span>
    <PinInput length={4} secret={pinIcon} type="numeric" autoComplete='off' inputMode="number" onChange={(value) => {setInputPin(value)}}/>
    <i onClick={() => {
      var state = !pinIcon;
      setPinIcon(state);
      }} className={pinIcon ? 'fa fa-eye' : 'fas fa-eye-slash'} style={{color: "#cbcbcb", fontSize: "15px", lineHeight: "40px", marginLeft: "10px" }}></i>
  
  </form>



</div>


<a href={void(0)} type="button" onClick={proceed} disabled={true} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn }>

    { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loading ? '' : 'Proceed' }

</a>


</div>





        </div>

  <div className="mb-5"></div>

    </div>
     {/* Page content ends here */}



{/* <PlaystoreCard/> */}


    </div>
</div>







    </div>
  )
}

export default Invoice;
